import { DialogService } from 'primeng/dynamicdialog';
import { PrimeNgModule } from './prime-ng.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { PropertySectionComponent } from './components/property-section/property-section.component';
import { LoaderComponent } from './components/loader/loader.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ContactInformationComponent } from './components/contact-information/contact-information.component';
import { NumberComponent } from './components/number/number.component';
import { StaticContactInformationComponent } from './components/static-contact-information/static-contact-information.component';
import { TableComponent } from './components/table/table.component';
import { CustomStepsComponent } from './components/custom-steps/custom-steps.component';
import { ClickOutsideDirective } from './directives/clickOutside/click-outside.directive';
import { AgreementComponent } from './components/agreement/agreement/agreement.component';
import { PhoneMaskDirective } from './directives/phoneMask/phone-mask.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { NewLinePipe } from './pipes/newLine/new-line.pipe';
import { AssistYouComponent } from '../components/customerdashboard/assist-you/assist-you.component';
import { ConfirmRegistrationComponent } from '../components/customerdashboard/confirm-registration/confirm-registration.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PropertySectionComponent,
    LoaderComponent,
    InputComponent,
    SelectComponent,
    RadioButtonComponent,
    CheckboxComponent,
    TextareaComponent,
    ContactInformationComponent,
    NumberComponent,
    StaticContactInformationComponent,
    TableComponent,
    CustomStepsComponent,
    ClickOutsideDirective,
    AgreementComponent,
    PhoneMaskDirective,
    ConfirmDialogComponent,
    NewLinePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    PrimeNgModule
  ],
  exports: [
    HttpClientModule,
    HeaderComponent,
    FooterComponent,
    PropertySectionComponent,
    LoaderComponent,
    InputComponent,
    SelectComponent,
    RadioButtonComponent,
    CheckboxComponent,
    TextareaComponent,
    ContactInformationComponent,
    NumberComponent,
    StaticContactInformationComponent,
    TableComponent,
    CustomStepsComponent,
    ClickOutsideDirective,
    AgreementComponent,
    NewLinePipe
  ],
  entryComponents: [
    StaticContactInformationComponent,
    AssistYouComponent,
    ConfirmRegistrationComponent
  ],
  providers: [
    DialogService,
    ConfirmationService
  ],
})
export class SharedModule { }
