import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor( private httpService: HttpService) { }

  getProjects(): Observable<any> {
    return this.httpService.get('/Project');
  }

  getProjectBySearch(searchText): Observable<any> {
    return this.httpService.get('/Project/'+searchText);
  }

  GetProjectsByUserId(emailId): Observable<any> {
    return this.httpService.get('/Project/GetProjectsByUserId/'+emailId);
  }

  ReserveIncentiveOffer(id,payload): Observable<any> {
    return this.httpService.put('/Project/ReserveIncentiveOffer/'+id, payload);
  }
  
  saveContactDetails(protectedProjectId,payload): Observable<any> {
    return this.httpService.post('/Contact/CommunicationTracker/'+protectedProjectId, payload);
  }
  
  getProjectCountByStage(): Observable<any> {
    return this.httpService.get('/UtilityDashboard/ProjectCountByStage');
  }

  getIncentiveBudgetStatus(): Observable<any> {
    return this.httpService.get('/UtilityDashboard/IncentiveBudgetStatus');
  }

  getInstallationForecast(): Observable<any> {
    return this.httpService.get('/UtilityDashboard/InstallationForecast');
  }

  getReviewApplicationByProjectId(protectedProjectId: string): Observable<any> {
    return this.httpService.get('/ReviewApplication/' + protectedProjectId)
  }

  saveReviewApplication(request: any) {
    return this.httpService.put('/ReviewApplication/'+request.id, request);
  }

  getDownloadDocument(attachment: any): Observable<Blob> {
    return this.httpService.postDownLoad('/File/Download' , attachment);
  }

  getInstallationLocations(): Observable<any> {
    return this.httpService.get('/UtilityDashboard/Installationlocations');
  }
}
