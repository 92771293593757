import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { AdditionalInformationComponent } from './components/additional-information/additional-information.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { HomeComponent } from './components/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PropertyAndContactComponent } from './components/property-and-contact/property-and-contact.component';
import { EligibilityCriteriaComponent } from './components/eligibility-criteria/eligibility-criteria.component';
import { FailThankYouComponent } from './components/fail-thank-you/fail-thank-you.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ReviewSolutionComponent } from './components/dashboard/review-solution/review-solution.component';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { ThankYouComponent } from './components/dashboard/thank-you/thank-you.component';
import { ClientRegistrationComponent } from './components/client-registration/client-registration.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CustomerDashboardComponent } from './components/customerdashboard/customer-dashboard/customer-dashboard.component';
import { ViewSolutionComponent } from './components/customerdashboard/view-solution/view-solution.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { IncentiveSuccessComponent } from './components/customerdashboard/incentive-success/incentive-success.component';
import { NextStepsComponent } from './components/customerdashboard/next-steps/next-steps.component';
import { ContractDetailsComponent } from './components/customerdashboard/contract-details/contract-details.component';
import { UpdatedContractDetailsComponent } from './components/customerdashboard/updated-contract-details/updated-contract-details.component';
import { IncentiveReservedComponent } from './components/customerdashboard/incentive-reserved/incentive-reserved.component';
import { ContractorBiddingComponent } from './components/customerdashboard/contractor-bidding/contractor-bidding.component';
import { UpdatedContractorBiddingComponent } from './components/customerdashboard/updated-contractor-bidding/updated-contractor-bidding.component';
import { NewProjectComponent } from './components/customerdashboard/new-project/new-project.component';
import { ClaimIncentiveComponent } from './components/customerdashboard/claim-incentive/claim-incentive.component';
import { FinalInspectionComponent } from './components/customerdashboard/final-inspection/final-inspection.component';
import { ProgramDetailsComponent } from './components/customerdashboard/program-details/program-details.component';
import { UploadedDocumentsComponent } from './shared/components/uploaded-documents/uploaded-documents.component';
import { ProjectSolutionComponent } from './components/customerdashboard/project-solution/project-solution.component';

const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'workflow', component: WorkflowComponent,
    children: [
      {
        path: 'eligibility-check',
        component: EligibilityCriteriaComponent
      },
      {
        path: 'contact-details',
        component: PropertyAndContactComponent
      },
      {
        path: 'property-information',
        component: AdditionalInformationComponent
      },
      {
        path: 'confirmation',
        component: ConfirmationComponent
      },
    ]
  },
  { path: 'contact-form-confirmation', component: FailThankYouComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'review-solution/:projectId/:protectedProjectId', component : ReviewSolutionComponent , canActivate: [AuthGuard]},
  { path: 'thank-you/:projectId/:protectedProjectId/:status', component : ThankYouComponent , canActivate: [AuthGuard]},
  { path: 'dashboard', component : DashboardComponent, canActivate: [AuthGuard]},
  { path: 'client-registration', component : ClientRegistrationComponent },
  { path: 'customer-registration', component : ClientRegistrationComponent },
  { path: 'customer-registration/:email', component : ClientRegistrationComponent },
  { path: 'customer-dashboard', component : CustomerDashboardComponent, canActivate: [AuthGuard]},
  { path: 'view-solution/:id/:projectId/:protectedProjectId', component : ViewSolutionComponent, canActivate: [AuthGuard]},
  { path: 'next-steps/:id/:projectId/:protectedProjectId', component : NextStepsComponent, canActivate: [AuthGuard]},
  { path: 'contract-details/:id/:projectId/:protectedProjectId', component : ContractDetailsComponent, canActivate: [AuthGuard]},
  { path: 'contractor-bidding/:id/:projectId/:protectedProjectId', component : ContractorBiddingComponent, canActivate: [AuthGuard]},
  { path: 'incentive-success/:id/:projectId/:protectedProjectId', component : IncentiveSuccessComponent, canActivate: [AuthGuard]},
  { path: 'privacy-policy', component : PrivacyPolicyComponent },
  { path: 'updated-contractor/:id/:projectId/:protectedProjectId', component : UpdatedContractDetailsComponent, canActivate: [AuthGuard] },
  { path: 'updated-contractor-bids/:id/:projectId/:protectedProjectId', component : UpdatedContractorBiddingComponent, canActivate: [AuthGuard] },
  { path: 'incentive-reserved/:id/:projectId/:protectedProjectId', component : IncentiveReservedComponent, canActivate: [AuthGuard] },
  { path: 'new-project', component : NewProjectComponent, canActivate: [AuthGuard]},
  { path: 'claim-incentive/:id/:projectId/:protectedProjectId', component : ClaimIncentiveComponent, canActivate: [AuthGuard] },
  { path: 'final-inspection/:id/:projectId/:protectedProjectId', component : FinalInspectionComponent, canActivate: [AuthGuard] },
  { path: 'program-details', component : ProgramDetailsComponent },
  { path: 'project-solution/:id/:projectId/:protectedProjectId', component : ProjectSolutionComponent, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
