import { HttpService } from 'src/app/core/http/http.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('WorkflowService');
@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  formData = {};
  contactDetails: any;
  propertyInformation: any;
  eligibleFormData = {};
  workFlowSteps = {
    step: 0,
    ownerType: ''
  };
  serviceNumber = {};
  private eligibleForProperty = new BehaviorSubject(false);
  private workFlowStepSource = new BehaviorSubject(this.workFlowSteps);
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService
  ) { }

  getEligibility() {
    return this.eligibleForProperty.asObservable();
  }
  setEligibility(status: boolean) {
    this.eligibleForProperty.next(status);
  }

  getWorkFlowStatus() {
    return this.workFlowStepSource.asObservable();
  }
  setWorkFlowStatus(obj) {
    this.workFlowSteps = {
      ...this.workFlowSteps,
      ...obj
    };
    this.workFlowStepSource.next(this.workFlowSteps);
  }
  setUserType(user) {
    sessionStorage.setItem('userType', JSON.stringify({ userType: user }));
  }
  getUserType() {
    return JSON.parse(sessionStorage.getItem('userType'));
  }
  setEligibleFormData(data) {
    this.eligibleFormData = {
      ...this.eligibleFormData,
      ...data
    };
    sessionStorage.setItem('eligibleFormData', JSON.stringify(this.eligibleFormData));
  }
  getEligibleFormData() {
    this.eligibleFormData = JSON.parse(sessionStorage.getItem('eligibleFormData'));
    return this.eligibleFormData;
  }
  getContactDetails() {
    // Return the Contact details data
    this.contactDetails = JSON.parse(sessionStorage.getItem('contactDetails'));
    return this.contactDetails;
  }

  setContactDetails(data) {
    // Update the Contact details data only when the Contact details Form had been validated successfully
    this.contactDetails = {
      ...this.contactDetails,
      ...data
    };
    sessionStorage.setItem('contactDetails', JSON.stringify(this.contactDetails));
  }
  setPropertyInformation(data) {
    this.propertyInformation = {
      ...this.propertyInformation,
      ...data
    };
    sessionStorage.setItem('propertyInformation', JSON.stringify(this.propertyInformation));
  }
  getPropertyInformation() {
    this.propertyInformation = JSON.parse(sessionStorage.getItem('propertyInformation'));
    return this.propertyInformation;
  }
  setFormData() {
    this.formData = {
      ...this.contactDetails,
      ...this.propertyInformation,
      eligibileAddress: {
        ...this.getEligibleFormData()
      },
      ...this.serviceNumber,
      isExistingCustomer: sessionStorage.getItem('msal.idtoken') ? true : false
    };
  }

  resetWorkFlowData() {
    this.contactDetails = {};
    this.propertyInformation = {};
    this.eligibleFormData = {};
    this.formData = {};
    sessionStorage.removeItem('contactDetails');
    sessionStorage.removeItem('propertyInformation');
    sessionStorage.removeItem('eligibleFormData');
  }

  removeSessionStorageItem(itemKey) {
    if (sessionStorage.getItem(itemKey)) {
      sessionStorage.removeItem(itemKey);
    }
  }
  getFormData() {
    return this.formData;
  }
  setServiceNumber(serviceNumber) {
    this.serviceNumber = serviceNumber;
  }
  getServiceNumber() {
    return this.serviceNumber;
  }
  createForm(formConfigArray) {
    const formGroup = {};
    formConfigArray.forEach(formControl => {
      if (!formControl.hidden) {
        if (formControl.type === 'formGroup') {
          if (formControl.controlType === 'checkboxgroup') {
            formGroup[formControl.controlName] =
              this.fb.group(this.createFormgroupObject(formControl.controls), {validators: this.atLeastOneCheckboxCheckedValidator()});
          } else {
            formGroup[formControl.controlName] =
              this.fb.group(this.createFormgroupObject(formControl.controls));
          }

        } else if (formControl.controlType === 'contactInfo') {
          formGroup[formControl.controlName] = this.createContactGroup(formControl.controls);
        } else {
          formGroup[formControl.controlName] = new FormControl('', this.bindValidations(formControl.validations || []));
        }
      }
    });
    return formGroup;
  }


  createContactGroup(contactInformation): FormGroup {
    let contactGroup = {};
    contactInformation.forEach((contactItem, i) => {
      if (contactItem.namePlace) {
        contactGroup = { ...contactGroup, ...this.createFormgroupObject(contactItem.namePlace) };
      } else if (contactItem.phoneAndTimeSpace) {
        contactGroup = { ...contactGroup, ...this.createFormgroupObject(contactItem.phoneAndTimeSpace) };
      }  else if (contactItem.streetAndCity) {
        contactGroup = { ...contactGroup, ...this.createFormgroupObject(contactItem.streetAndCity) };
      } else if (contactItem.stateAndZip) {
        contactGroup = { ...contactGroup, ...this.createFormgroupObject(contactItem.stateAndZip) };
      } else {
        if (!contactItem.hidden) {
          contactGroup[contactItem.controlName] = new FormControl('', this.bindValidations(contactItem.validations || []));
        }
      }
    });
    return new FormGroup(contactGroup);
  }


  createFormgroupObject(objectArray) {
    const groupObj = {};
    objectArray.forEach((currentObj) => {
      if (!currentObj.hidden) {
        groupObj[currentObj.controlName] = new FormControl('', this.bindValidations(currentObj.validations || []));
      }
    });
    return groupObj;
  }

  createFormArrayOfFormControls(controlsArray) {
    const controlesFormArray = [];
    controlsArray.forEach((element, i) => {
      controlesFormArray.push(this.fb.control(false));
    });
    return controlesFormArray;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        if (valid.active) {
          if (valid.customValidator) {
            validList.push(
              this.atLeastOneCheckboxCheckedValidator()
            );
          } else if (valid.value) {
            validList.push(Validators[valid.validator](valid.value));
          } else if (!valid.value) {
            validList.push(Validators[valid.validator]);
          }
        }
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        control.markAsTouched({ onlySelf: true });
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }

    });
  }

  submitWorkflowFormData() {
    log.debug('this.formData', this.formData);
    return this.httpService.post(CLIENT_CONFIG.apis.submitWorkflowFormData, this.formData);
  }

  isEmailAlreadyTaken() {
    const contactDetails = this.getContactDetails();
    const propertyInformation = this.getPropertyInformation();

    if (contactDetails && propertyInformation && propertyInformation.contactInfoRepesentative) {
      if (contactDetails.contactInfo.email === propertyInformation.contactInfoRepesentative.email) {
        return true;
      }
      return false;
    }
  }

  atLeastOneCheckboxCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked = 0;

      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }
}
