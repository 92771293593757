import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EligiblePropertyService } from 'src/app/services/eligible-property/eligible-property.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { MsalService } from '@azure/msal-angular';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { Subscription } from 'rxjs';
import { Logger } from 'src/app/core/logger.service';
// declare module 'google';
const log = new Logger('NewProjectComponent');

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss'],
  styles: [`
        .ui-steps .ui-steps-item {
            width: 25%;
        }

        .ui-steps.steps-custom {
            margin-bottom: 30px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
            padding: 0 1em;
            overflow: visible;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
            background-color: #0081c2;
            color: #FFFFFF;
            display: inline-block;
            width: 36px;
            border-radius: 50%;
            margin-top: -14px;
            margin-bottom: 10px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
            color: #555555;
        }
    `],
  encapsulation: ViewEncapsulation.None
})
export class NewProjectComponent implements OnInit, OnDestroy {

  constructor(
    private fb: FormBuilder,
    private eligiblePropertyService: EligiblePropertyService,
    private workflowService: WorkflowService,
    private router: Router,
    private toasterService: ToasterService,
    private appInsightsService: AppInsightsService,
    private authService: MsalService,
    private contractorService: ContractorService) { }

  items : any;
  activeIndex: number;
  textLanguage: any;
  newProjectForm: FormGroup;

  contactInfoSubscription: Subscription;
  verifyEligibilitySubscritpion: Subscription;
  setStreetAddress = true;

  @Input() adressType = 'address';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', { static: true }) addresstext: any;

  addressForm = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    longRoute: '',
    streetNameFc: ''
  };

  ngOnInit() {
    this.intializeCustomSteps();
    this.activeIndex = 0;
    this.textLanguage = AppConfigService.settings.newProjectPage;
    this.newProjectForm = this.fb.group({
      streetAddress: [''],
      unit: [''],
      city: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]],
      state: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]],
      postalCode: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]],
      streetNumber: [''],
      streetNameFc: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]]
    });
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  intializeCustomSteps() {
    this.items = [
      {
        label: 'Eligibility Check',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        icon: 'fa fa-user',
        label: 'Contact Details',
        command: (event: any) => {
          this.activeIndex = 1;
        }
      },
      {
        icon: 'fa fa-search',
        label: 'Property Information',
        command: (event: any) => {
          this.activeIndex = 2;
        }
      },
      {
        icon: 'fa fa-check',
        label: 'Confirmation  ',
        command: (event: any) => {
          this.activeIndex = 3;
        }
      }
    ];
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        types: [this.adressType]
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      // tslint:disable-next-line: no-unused-expression
      place.address_components ? log.debug('GOOGLE API VALUES', place.address_components) : '';
      this.invokeEvent(place);
      this.populateAddress(place);
    });
  }

  invokeEvent(place) {
    this.setAddress.emit(place);
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  populateAddress(place) {
    this.setStreetAddress = false;
    const addressComponents: any = place.address_components;
    addressComponents.forEach((addressComponent: any, index: number) => {
      const types = addressComponent.types;
      types.forEach((type: string, i) => {
        if (type === 'locality') {
          this.addressForm.city = addressComponent.long_name;
        }
        if (type === 'administrative_area_level_1') {
          this.addressForm.state = addressComponent.short_name;
        }
        if (type === 'street_number') {
          this.addressForm.streetNumber = addressComponent.long_name;
        }
        if (type === 'route') {
          this.addressForm.route = addressComponent.short_name;
          this.addressForm.longRoute = addressComponent.long_name;
          this.addressForm.streetNameFc = addressComponent.long_name;
        }
        if (type === 'postal_code') {
          this.addressForm.postalCode = addressComponent.short_name;
        }
      });
    });
    this.newProjectForm.patchValue({
      city: this.addressForm.city,
      state: this.addressForm.state,
      postalCode: this.addressForm.postalCode,
      streetNumber: this.addressForm.streetNumber,
      streetNameFc: this.addressForm.streetNameFc
    });
  }

  onSubmit(): void {
    if (this.newProjectForm.valid) {
      this.workflowService.setEligibility(true);
      const formData = {
        ...this.newProjectForm.value,
        StreetName: this.addressForm.route,
        StreetNameLong: this.addressForm.longRoute,
        streetAddress: this.newProjectForm.controls.streetNumber.value + ' ' + this.newProjectForm.controls.streetNameFc.value,
        StreetNameManual: this.newProjectForm.controls.streetNameFc.value
      };

      log.debug('FINAL VALUES', formData);

      this.verifyEligibilitySubscritpion = this.eligiblePropertyService.verifyEligibilityAddress(formData).subscribe((response) => {
        if (response && response.success) {
          if (response.dataValues && response.dataValues.length) {
            response.dataValues.forEach((element) => {
              if (element.name === 'Address1') {
                formData.streetAddress = element.value;
              } else if (element.name === 'City') {
                formData.city = element.value;
              } else if (element.name === 'State') {
                formData.state = element.value;
              } else if (element.name === 'ZipCode') {
                formData.postalCode = element.value;
              } else if (element.name === 'Reference1') {
                this.workflowService.setServiceNumber({
                  serviceNumber: element.value
                });
              }
            });
          } else {
            this.workflowService.setServiceNumber({
              serviceNumber: ''
            });
          }
          this.workflowService.setEligibleFormData(formData);
          this.workflowService.setEligibility(true);
          const email = this.authService.getUser().idToken['emails'][0];
          this.contactInfoSubscription = this.contractorService.getContactInfo(email).subscribe(res => {
            res.userType = '';
            this.workflowService.setContactDetails(res);
            this.router.navigate(['workflow/property-information']);
          });
        } else {
          this.workflowService.setEligibility(false);
          this.router.navigate(['workflow/eligibility-check']);
        }
      }, (error) => {
        this.handleErrors(error);
      });
    } else {
      this.workflowService.validateAllFormFields(this.newProjectForm);
      this.workflowService.setEligibility(false);
    }

  }

  handleErrors(error) {
    if (error && error.error) {
      if (error.error.errorCode === 'e001') {
        this.toasterService.showError(error.error.message);
      } else {
        this.appInsightsService.logException(error);
        this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
      }
    } else {
      this.appInsightsService.logException(error);
      this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
    }
  }

  ngOnDestroy() {
    if (this.contactInfoSubscription) {
      this.contactInfoSubscription.unsubscribe();
    }

    if (this.verifyEligibilitySubscritpion) {
      this.verifyEligibilitySubscritpion.unsubscribe();
    }
  }

}
