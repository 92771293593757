import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('WorkflowComponent');
@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  styles: [`
        .ui-steps .ui-steps-item {
            width: 25%;
        }

        .ui-steps.steps-custom {
            margin-bottom: 30px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
            padding: 0 1em;
            overflow: visible;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
            background-color: #0081c2;
            color: #FFFFFF;
            display: inline-block;
            width: 36px;
            border-radius: 50%;
            margin-top: -14px;
            margin-bottom: 10px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
            color: #555555;
        }
    `],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowComponent implements OnInit, AfterContentChecked, OnDestroy {
  ownerType: '';
  items: MenuItem[];
  activeIndex: number;
  allowPropertyAndContact = true;
  allowAdditionalInformation = false;
  allowConfirmation = false;
  isEligibleForProperty: boolean;
  constructor(
    private workflowService: WorkflowService,
    private cdr: ChangeDetectorRef
  ) { }

  // goToAdditionalInformation(flag: boolean) {
  //   this.activeIndex = 2;
  //   this.allowAdditionalInformation = flag;
  //   this.allowPropertyAndContact = !flag;
  //   this.allowConfirmation = !flag;
  //   this.workflowService.setWorkFlowStatus({ step: this.activeIndex });
  // }
  // goToPropertyAndContact(flag: boolean) {
  //   this.activeIndex = 1;
  //   this.allowPropertyAndContact = flag;
  //   this.allowAdditionalInformation = !flag;
  //   this.allowConfirmation = !flag;
  //   this.workflowService.setWorkFlowStatus({ step: this.activeIndex });
  // }
  // goToConfirmation(flag: boolean) {
  //   this.activeIndex = 3;
  //   this.allowConfirmation = flag;
  //   this.allowPropertyAndContact = !flag;
  //   this.allowAdditionalInformation = !flag;
  //   this.workflowService.setWorkFlowStatus({ step: this.activeIndex });

  // }
  ngOnInit() {
    this.workflowService.getWorkFlowStatus().subscribe((workFlow) => {
      this.activeIndex = workFlow.step;
    });
    // this.workflowService.getEligibility().subscribe((status: boolean) => {
    //   if (status) {
    //     this.activeIndex = 1;
    //     this.workflowService.setWorkFlowStatus({ step: this.activeIndex });
    //     this.isEligibleForProperty = status;
    //   } else {
    //     this.activeIndex = 0;
    //   }

    // });
    this.items = [
      {
        label: 'Eligibility Check',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        icon: 'fa fa-user',
        label: 'Contact Details',
        command: (event: any) => {
          this.activeIndex = 1;
        }
      },
      {
        icon: 'fa fa-search',
        label: 'Property Information',
        command: (event: any) => {
          this.activeIndex = 2;
        }
      },
      {
        icon: 'fa fa-check',
        label: 'Confirmation  ',
        command: (event: any) => {
          this.activeIndex = 3;
        }
      }
    ];
  }

  ngAfterContentChecked() {
    // your code to update the model
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    log.debug('workflow component destroyed');
    this.workflowService.resetWorkFlowData();
  }

}
