import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SpinnerModule } from 'primeng/spinner';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { CarouselModule } from 'primeng/carousel';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { StepsModule } from 'primeng/steps';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageService } from 'primeng/api';
import {TableModule} from 'primeng/table';
import {ChartModule} from 'primeng/chart';
import {ProgressBarModule} from 'primeng/progressbar';
import {GMapModule} from 'primeng/gmap';
import { FileUploadModule } from 'primeng/fileupload'; 
import {TabViewModule} from 'primeng/tabview';
import {TooltipModule} from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar';
import {ScrollPanelModule} from 'primeng/scrollpanel';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InputTextModule,
    CheckboxModule,
    PasswordModule,
    DropdownModule,
    InputTextareaModule,
    AutoCompleteModule,
    MultiSelectModule,
    RadioButtonModule,
    SpinnerModule,
    TriStateCheckboxModule,
    CarouselModule,
    MessageModule,
    ToastModule,
    StepsModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    TableModule,
    ChartModule,
    ProgressBarModule,
    GMapModule,
    TabViewModule,
    FileUploadModule,
    TooltipModule,
    CalendarModule,
    ScrollPanelModule
  ],
  exports: [
    InputTextModule,
    CheckboxModule,
    PasswordModule,
    DropdownModule,
    InputTextareaModule,
    AutoCompleteModule,
    MultiSelectModule,
    RadioButtonModule,
    SpinnerModule,
    TriStateCheckboxModule,
    CarouselModule,
    MessageModule,
    ToastModule,
    StepsModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    TableModule,
    ChartModule,
    ProgressBarModule,
    GMapModule,
    TabViewModule,
    FileUploadModule,
    TooltipModule,
    CalendarModule,
    ScrollPanelModule
  ],
  providers: [MessageService]
})
export class PrimeNgModule { }
