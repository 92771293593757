import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';

@Injectable({
  providedIn: 'root'
})
export class EligiblePropertyService {

  constructor(private httpService: HttpService) { }
  verifyEligibilityAddress(eligibilityAddress) {
    return this.httpService.post(CLIENT_CONFIG.apis.eligibilityAddress, eligibilityAddress);
  }
  verifyServiceNumber(serviceNumber) {
    return this.httpService.post(CLIENT_CONFIG.apis.verifyServiceNumber, serviceNumber);
  }
  verifyContactAddress(contactInfo) {
    return this.httpService.post(`${CLIENT_CONFIG.apis.verifyContactAddress}`, contactInfo);
  }
}
