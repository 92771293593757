import { Component, OnInit } from '@angular/core';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Component({
  selector: 'app-eligibility-criteria',
  templateUrl: './eligibility-criteria.component.html'
})
export class EligibilityCriteriaComponent implements OnInit {
  isEligibleForProperty: boolean;
  isEnableFailThankyou: boolean;
  constructor(private workflowService: WorkflowService) {
    this.workflowService.setWorkFlowStatus({ step: 0 });
  }

  enableFailThankYou(status) {
    this.isEnableFailThankyou = status;
    this.isEligibleForProperty = !this.isEligibleForProperty;
  }

  ngOnInit() {
    this.workflowService.getEligibility().subscribe((status: boolean) => {
        this.isEligibleForProperty = status;
    });
  }

}
