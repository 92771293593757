import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { forkJoin } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class ClaimIncentiveService {

  constructor(private httpService: HttpService) { }
  
  GetManufactures() {
    return this.httpService.get('/Charger/Manufacturers');
  }

  GetModels() {
    return this.httpService.get('/Charger/Models');
  }

  GetMakeAndModel() {
      let manufacturers = this.httpService.get('/Charger/Manufacturers');
      let models = this.httpService.get('/Charger/Models');
      return forkJoin([manufacturers, models]);
  }

  SaveClaimIncentive(id: string, body: any) {
    return this.httpService.put('/Project/ClaimIncentive/'+ id, body);
  }
}