import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ClientRegistrationService } from 'src/app/services/client-registration/client-registration.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit {
  email: any;
  password: any;
  registrationType: any;
  clientRegistrationSubscription: Subscription;

  constructor(
    public config: DynamicDialogConfig,
    private clientRegistrationService: ClientRegistrationService,
    private authService: MsalService,
    public loaderService: LoaderService,
    private tenantService: TenantService,
    private toasterService: ToasterService,
    private appInsightsService: AppInsightsService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.email = this.config.data.email;
    this.password = this.config.data.password;
    this.registrationType = this.config.data.registrationType;
  }

  submit() {
        this.authService.authority = environment.adb2c.AdSignInUrl;
        this.loaderService.setSpinner(true);
        const extraQueryParameters = this.tenantService.getQueryStringParms();
        this.authService.loginRedirect(null, extraQueryParameters);
  }

}
