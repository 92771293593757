import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Router } from '@angular/router';
import { Logger } from 'src/app/core/logger.service';
import { EligiblePropertyService } from 'src/app/services/eligible-property/eligible-property.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';

const log = new Logger('StaticContactInformationComponent');
@Component({
  selector: 'app-static-contact-information',
  templateUrl: './static-contact-information.component.html',
  styleUrls: ['./static-contact-information.component.scss']
})
export class StaticContactInformationComponent implements OnInit {
  contactDetails: any;
  popupContent: any
  propertyAndContactform: FormGroup;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private workflowService: WorkflowService,
    private router: Router,
    private eligiblePropertyService: EligiblePropertyService,
    private toasterService: ToasterService,
    private appInsightsService: AppInsightsService
  ) {
    log.debug(this.config);
  }
  close() {
    this.ref.close();
  }
  submit() {
    if (this.propertyAndContactform.valid) {
      const descriptionElement = document.getElementById('description') as HTMLInputElement;
      const contactInfo = {
        ...this.propertyAndContactform.value,
         description: (descriptionElement) ? descriptionElement.value : ''
      };
      this.eligiblePropertyService.verifyContactAddress(contactInfo)
        .subscribe((response) => {
          log.debug('response', response);
          if (response && response.success) {
            if (this.config.data.fromWhere === 'header') {
              this.router.navigate(['contact-form-confirmation']);
            }
            this.ref.close(true);
          } else {
            this.appInsightsService.logException(response);
            this.toasterService.showError(response.message);
          }
        }, (error) => {
          this.handleErrors(error);
        });
    } else {
      this.workflowService.validateAllFormFields(this.propertyAndContactform);
    }
  }
  ngOnInit() {
    this.contactDetails = AppConfigService.settings.notEligiblePage.contactDetails;
    this.popupContent = AppConfigService.settings.notEligiblePage.popupSection;
    let formGroup = {};
    formGroup = this.workflowService.createForm(this.contactDetails);
    this.propertyAndContactform = new FormGroup(formGroup);
    let portalOrigination = window.location.pathname.indexOf("eligibility-check") >= 0 ? "Ineligible Customer" : "Home Page";
    this.propertyAndContactform.addControl('PortalOrigination', new FormControl(portalOrigination));
    log.debug('this.propertyAndContactform', this.propertyAndContactform.value);
  }

  handleErrors(error) {
    if (error && error.error) {
      if (error.error.errorCode === 'e001') {
        this.toasterService.showError(error.error.message);
      } else {
        this.appInsightsService.logException(error);
        this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
      }
    } else {
      this.appInsightsService.logException(error);
      this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
    }
  }
}
