import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'app-custom-steps',
  templateUrl: './custom-steps.component.html',
  styleUrls: ['./custom-steps.component.scss']
})
export class CustomStepsComponent implements OnInit {
  @Input() activeIndex = 0;

  @Input() model: MenuItem[];

  @Input() readonly = true;

  @Input() style: any;

  @Input() styleClass: string;

  @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();
  constructor() { }

  itemClick(event: Event, item: MenuItem, i: number) {
    if (this.readonly || item.disabled) {
      event.preventDefault();
      return;
    }

    this.activeIndexChange.emit(i);

    if (!item.url) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item,
        index: i
      });
    }
  }

  ngOnInit() {
  }

}
