import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {BroadcastService} from "@azure/msal-angular";
import { MsalService} from "@azure/msal-angular";
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private broadcastService: BroadcastService,
     private authService: MsalService, 
     private route: Router
  ) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.authService.getUser() || sessionStorage.user != undefined) {
      return true;
    }
    else {
      this.route.navigate(["/home"]);
      return false;
    }
  }

}
