import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Router } from '@angular/router';
import { ContactInformationDialogService } from '../../services/contact-information-dialog/contact-information-dialog.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footer = AppConfigService.settings.footer;
  routerUrl:string;
  routertemp: Router;
  toggle = null;
  menu = null;
  constructor(
    private router: Router,
    private contactInformationDialogService: ContactInformationDialogService
  ) { }

  ngOnInit() {
    this.routertemp=this.router;
   
  }

  openContactDialog(flag) {
    this.contactInformationDialogService.show(flag, 'footer');
    this.toggleMenu();
  }

  toggleMenu() {
    this.toggle = document.querySelector(".navbar-toggler");
    this.menu = document.querySelector(".nav");
    if (this.menu.classList.contains("active")) {
      this.menu.classList.remove("active");
      // adds the menu (hamburger) icon
      this.toggle.querySelector("span").classList.remove("n-close");
      this.toggle.querySelector("span").classList.add("n-bars");
    } else {
      this.menu.classList.add("active");
      // adds the close (x) icon
      this.toggle.querySelector("span").classList.remove("n-bars");
      this.toggle.querySelector("span").classList.add("n-close");
    }
  }
}
