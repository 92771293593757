import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-dashboard',
  templateUrl: './customer-dashboard.component.html',
  styleUrls: ['./customer-dashboard.component.scss']
})
export class CustomerDashboardComponent implements OnInit {

  cols: any;
  records: any;
  dashboardSection: any;
  emailId = this.authService.getUser().idToken['emails'][0];
  projectsCompleted: number;
  projectsInProgress: number;

  uplo: File;


  private dashboardSubscription: Subscription;

  constructor(private dashboardService: DashboardService,
              private authService: MsalService,
              private router: Router) { 
              }

  ngOnInit() {
    this.dashboardSection = AppConfigService.settings.dashboard;
    this.cols = [
      { field: 'projectStage', header: 'Stage', width: '25%' },
      { field: 'projectId', header: 'Project ID', width: '10%'},
      { field: 'businessName', header: 'Business Name', width: '25%'},
      { field: 'fullAddress', header: 'Project address', width: '30%'},
      { field: 'chargers', header: 'Chargers', width: '10%'},
      { field: 'button', header: '', width: '15%'}
    ];
    this.loadRecords();
  }

  loadRecords(){
    this.dashboardSubscription = this.dashboardService.GetProjectsByUserId(this.emailId).subscribe(response =>{
      if(response){
        this.records = response;
        this.records.sort(function (a, b) {
          return b.projectId.localeCompare(a.projectId);
        });
        this.projectsCompleted = this.records.filter(record => record.projectStatus === "Incentive Paid").length;
        this.projectsInProgress = this.records.filter(record => record.projectStatus !== "Incentive Paid").length;
      }
    });
  }

  newProjectClick() {
    this.router.navigate(['/new-project']);
  }

  viewProject(record){
    this.router.navigate(['/next-steps',btoa(record.id), btoa(record.projectId), btoa(record.protectedProjectId)]);
  }
}
