import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {
  @Input() input: any;
  @Input() group: FormGroup;
  @Input() option: any;
  @Output() handleClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  handleChange(event) {
    this.handleClick.emit(event);
  }

}
