import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Charger } from 'src/app/models/charger';
import { ClaimIncentiveService } from 'src/app/services/claim-incentive/claim-incentive.service';
import { formatDate } from '@angular/common';
import { ContractorService } from 'src/app/services/contractor/contractor.service';

@Component({
  selector: 'app-claim-incentive',
  templateUrl: './claim-incentive.component.html',
  styleUrls: ['./claim-incentive.component.scss'],
  styles: [`
  .ui-steps .ui-steps-item {
    width: 20%;
  }

  .ui-steps.steps-custom {
      margin-bottom: 30px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
      padding: 0 1em;
      overflow: visible;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
      background-color: #0081c2;
      color: #FFFFFF;
      display: inline-block;
      width: 36px;
      border-radius: 50%;
      margin-top: -14px;
      margin-bottom: 10px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
      color: #555555;
  }
  .ui-inputtext {
    padding: 0.300em !important;
  }

`],
  encapsulation: ViewEncapsulation.None
})
export class ClaimIncentiveComponent implements OnInit, OnDestroy {
  date: Date;

  constructor(public loaderService: LoaderService,
              private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private claimIncentiveService: ClaimIncentiveService,
              private contractorService: ContractorService
  ) { }


  activeIndex: number;
  items: any;
  displayText: any;
  formAttachments: any[] = [];
  invoiceAttachments: any[] = [];
  projectId: string;
  protectedProjectId: string;
  id: string;

  manufacturers: any[] = [];
  models: any[] = [];
  selectedModel: any[] = [];

  claimIncentiveForm: FormGroup;

  routeSubscription: Subscription;
  makeModelSubscription: Subscription;
  formUploadSubscription: Subscription;
  invoiceUploadSubscription: Subscription;
  claimIncentiveSubscription: Subscription;

  ngOnInit() {
    this.displayText = AppConfigService.settings.claimIncentivePage;

    if (!this.displayText.showTotalProjectCost) {
      this.claimIncentiveForm = this.fb.group({
        date: ['', [Validators.required]],
        chargers: this.fb.array([this.fb.group({
          chargerMake: [null, [Validators.required]],
          chargerModel: [null, [Validators.required]],
          serialNumber: ['', [Validators.required]]
        }),
        ])
      });
    } else {
      this.claimIncentiveForm = this.fb.group({
        date: ['', [Validators.required]],
        chargers: this.fb.array([this.fb.group({
          chargerMake: [null, [Validators.required]],
          chargerModel: [null, [Validators.required]],
          serialNumber: ['', [Validators.required]]
        }),
        ]),
        totalProjectCost: ['', [Validators.required]]
      });
    }

    this.date = new Date();

    this.intializeCustomSteps();
    this.activeIndex = 4;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
      this.id = atob(params.id);
    });

    this.makeModelSubscription = this.claimIncentiveService.GetMakeAndModel().subscribe(res => {
      this.manufacturers = res[0];
      this.models = res[1];
    });
  }

  intializeCustomSteps() {
    this.items = [
      {
        label: 'Onsite Evaluation',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        label: 'Scope Selection',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        label: 'Request Bids',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-list-ul'
      },
      {
        label: 'Contract Details',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-shield'
      },
      {
        icon: 'fa fa-check',
        label: this.displayText.customStep5,
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  onSelectFormAttachment(data: { files: File }) {
    for (var i = 0; i < data.files['length']; i++) {
      this.formAttachments.push(data.files[i]);
    }
  }

  public onRemoveFormAttachment(data: File) {
    this.loaderService.setSpinner(true);
    let selectedAttachment = this.formAttachments.find(x => x.name == data['file'].name);
    if (selectedAttachment) {
      this.formAttachments = this.formAttachments.filter(x => x.name != data['file'].name);
      this.loaderService.setSpinner(false);
    }
  }

  public onSelectInvoiceAttachment(data: { files: File }) {
    for (var i = 0; i < data.files['length']; i++) {
      this.invoiceAttachments.push(data.files[i]);
    }
  }

  public onRemoveInvoiceAttachment(data: File) {
    this.loaderService.setSpinner(true);
    let selectedAttachment = this.invoiceAttachments.find(x => x.name == data['file'].name);
    if (selectedAttachment) {
      this.invoiceAttachments = this.invoiceAttachments.filter(x => x.name != data['file'].name);
      this.loaderService.setSpinner(false);
    }
  }

  onSelectManufacturer(selectInput: string) {
    let selectedManufacturerModels = this.models.filter((item) => item.make == selectInput);
    this.selectedModel[selectInput] = selectedManufacturerModels[0].models;
  }

  addCharger() {
    (<FormArray>this.claimIncentiveForm.get('chargers')).push(this.fb.group({
      chargerMake: [null, [Validators.required]],
      chargerModel: [null, [Validators.required]],
      serialNumber: ['', [Validators.required]]
    }));
  }

  removeCharger(i) {
    (<FormArray>this.claimIncentiveForm.get('chargers')).removeAt(i);
  }

  submit() {
    let allAttachments;
    let formData = new FormData();
    this.formAttachments.forEach(x => {
      formData.append('file[]', x, x.name);
    });

    this.formUploadSubscription = this.contractorService.upload(formData, this.id, 'W-9').subscribe(response => {
      let formAttachments = response.map((d, i) => {
        return {
          attachmentType: 'W-9',
          attachmentName: this.formAttachments[i].name,
          fileKey: d.guid,
          rebate: this.id,
          status: 'Uploaded',
          attachmentSource: 'EV Portal'
        };
      });

      formData = new FormData();
      this.invoiceAttachments.forEach(x => {
        formData.append('file[]', x, x.name);
      });

      this.invoiceUploadSubscription = this.contractorService.upload(formData, this.id, 'Invoice').subscribe(response => {
        let invoiceAttachments = response.map((d, i) => {
          return {
            attachmentType: 'Invoice',
            attachmentName: this.invoiceAttachments[i].name,
            fileKey: d.guid,
            rebate: this.id,
            status: 'Uploaded',
            attachmentSource: 'EV Portal'
          };
        });

        allAttachments = [...formAttachments, ...invoiceAttachments];

        let installedChargers = (<FormArray>this.claimIncentiveForm.get('chargers')).controls.map((d, i) => {
          return {
            project: this.id,
            chargerModel: d['controls'].chargerModel.value,
            chargerSerialNumber: d['controls'].serialNumber.value
          };
        });

        let project = {
          'projectStage': 'Verification',
          'projectStatus': 'Installation Completed',
          'installationCompletedDate': formatDate(this.claimIncentiveForm.controls.date.value, 'yyyy-MM-dd', 'en_US'),
          'projectAttachments': allAttachments,
          'installedChargers': installedChargers,
          'totalProjectCost' : this.claimIncentiveForm.controls.totalProjectCost ? this.claimIncentiveForm.controls.totalProjectCost.value : 0,
        };

        this.claimIncentiveSubscription = this.claimIncentiveService.SaveClaimIncentive(this.id, project).subscribe(res => {
          this.router.navigate(['/final-inspection', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
        });
      });
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }

    if (this.makeModelSubscription) {
      this.makeModelSubscription.unsubscribe();
    }

    if (this.formUploadSubscription) {
      this.formUploadSubscription.unsubscribe();
    }

    if (this.invoiceUploadSubscription) {
      this.invoiceUploadSubscription.unsubscribe();
    }

    if (this.claimIncentiveSubscription) {
      this.claimIncentiveSubscription.unsubscribe();
    }
  }

}
