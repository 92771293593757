import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  privacyPolicy = AppConfigService.settings.privacyPolicy;

  constructor() { }

  ngOnInit() {
    //document.getElementById('footer-element').style.display = "none";
  }

}
