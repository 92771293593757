import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss']
})
export class ProgramDetailsComponent implements OnInit {

  constructor(private router : Router) { }

  displayText: any;
  ngOnInit() {
    this.displayText = AppConfigService.settings.programDetailsPage;

  }

  goBack(){
    this.router.navigate(['dashboard']);
  }

}
