import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Logger } from 'src/app/core/logger.service';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { AppInsightsService } from 'src/app/services/app-insights.service';
const log = new Logger('AdditionalInformationComponent');
@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit {
  btnTexts = AppConfigService.settings.workFlow.propertyInformation;
  additionalInfoTitleSection = AppConfigService.settings.workFlow.propertyInformation.title;
  additionalInfo = AppConfigService.settings.workFlow.propertyInformation.propertyManager;
 
  additionalInformationform: FormGroup;
  contactDetails: any;
  chargersValidation = false;
  @Output() setNext = new EventEmitter<boolean>();
  @Output() setBack = new EventEmitter<boolean>();
  workFlowStatus: { step: number; ownerType: string; };
  constructor(
    private fb: FormBuilder,
    private workflowService: WorkflowService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private toasterService: ToasterService,
    private appInsightsService: AppInsightsService
  ) {
    log.debug('AdditionalInformationComponent----constructor');
    this.contactDetails = this.workflowService.getContactDetails();
    if (this.contactDetails &&
        (this.contactDetails.userType.toLowerCase().includes('tenant') ||
         this.contactDetails.userType.toLowerCase().includes('contractor'))) {
      this.additionalInfo = AppConfigService.settings.workFlow.propertyInformation.tenant;
    }
  }

  save(form: any): boolean {
    this.workflowService.setPropertyInformation(this.additionalInformationform.value);
    if (!form.valid) {
      return false;
    }
    return true;
  }
  goToBack() {
    this.setBack.emit(true);
    if(sessionStorage.getItem('msal.idtoken')) {
      this.router.navigate(['new-project']);
    }
    else {
      this.save(this.additionalInformationform);
      this.router.navigate(['workflow/contact-details']);
    }
  }
  
  goToNext() {
    log.debug('additionalInformationform', this.additionalInformationform.value);
    const chargersCount = Object.keys(this.additionalInformationform.value.chargersInterested).map(e => {
          return this.additionalInformationform.value.chargersInterested[e];
    }).reduce((cnt, e) => {
          return cnt + Number(e);
    }, 0);

    if (chargersCount >= 1) {
      this.chargersValidation = false;
      if (this.save(this.additionalInformationform)) {
        if (this.workflowService.isEmailAlreadyTaken()) {
          this.toasterService.showError('Email is already in use.');
          return;
        }
        if(sessionStorage.getItem('msal.idtoken')) {
          let role = sessionStorage.getItem("role");
          let contactDetails = this.workflowService.getContactDetails();
          contactDetails.userType = role
          this.workflowService.setContactDetails(contactDetails);
           this.workflowService.setUserType(role);
        }
      
        this.workflowService.setFormData();
        this.workflowService.submitWorkflowFormData().subscribe((response) => {
          if (response.success) {
            this.router.navigate(['workflow/confirmation']);
          } else {
            if (response.errorCode === 'e001') {
              this.toasterService.showError(response.message);
            } else {
              this.appInsightsService.logException(response);
              this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
            }
          }
        }, (error) => {
          if (error && error.error) {
            if (error.error.errorCode === 'e001') {
              this.toasterService.showError(error.error.message);
            } else {
              this.appInsightsService.logException(error);
              this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
            }
          } else {
            this.appInsightsService.logException(error);
            this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
          }

          log.error('error', error);
        });

      } else {
        this.workflowService.validateAllFormFields(this.additionalInformationform);
      }
    } else {
          this.chargersValidation = true;
    }
  }

  ngOnInit() {
    log.debug('AdditionalInformationComponent');
    let formGroup = {};
    formGroup = this.workflowService.createForm(this.additionalInfo);
    this.additionalInformationform = new FormGroup(formGroup);
    this.workflowService.setWorkFlowStatus({ step: 2 });
    const propertyInformation = this.workflowService.getPropertyInformation();
    this.additionalInformationform.patchValue({
      ...propertyInformation
    });
  }
}
