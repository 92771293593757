import { Theme } from './theme';

export const pceTheme: Theme = {
    name: 'pce',
    favicon: 'assets/img/pce_favicon.png',
    properties: {
        '--base_text_color' : '#292929',
        '--secondary_text_color' : '#707070',
        '--tertiary_text_color' : '#C0C8D8',
        '--heading_text_color' : '#fdfdfd',
        '--sub_heading_text_color' : '#222222',
        '--title_heading_text_color' : '#3e3e3e',
        '--description_text_color' : '#909caa',
        '--base_link_text_color' : '#69B342',
        '--base_link_hover_text_color' : '#69B342',
        '--base_nav_background_color' : '#F8F8F8',
        '--base_nav_border_color' : '#d6dff9',
        '--base_sperator_color' : '#A3B9FF',
        '--white_color' : '#ffffff',
        '--form_label_text_color' : '#262626',
        '--form_label_background_color' : '#ffffff',
        '--form_check_box_label_color' : '#4b4b4b',
        '--primary_button_text_color' : '#445A1D',
        '--primary_button_font_weight' : '700',
        '--primary_button_background_color' : '#69B342',
        '--primary_button_hover_text_color' : '#0079C1',
        '--primary_button_hover_background_color' : '#FDE80',
        '--secondary_button_text_color' : '#ffffff',
        '--secondary_button_background_base_color' : '#0373C0',
        '--secondary_button_background_secondary_color' : '#0077C8',
        '--secondary_button_hover_text_color' : '#ffffff',
        '--secondary_button_hover_background_base_color' : '#0077C8',
        '--secondary_button_hover_background_secondary_color' : '#056DB8',
        '--carousel_background_color' : '#205d96',
        '--craousel_number_text_color' : '#245D88',
        '--craousel_button_background_color' : '#212020',
        '--craousel_button_text_color' : '#EDEDED',
        '--base_font_family' : '\'Myriad Pro Regular\', sans-serif',
        '--light_font_family' : '\'Myriad Pro Light\', sans-serif',
        '--bold_font_family' : '\'Myriad Pro Bold\', sans-serif',
        '--bold_italic_font_family' : '\'Myriad Pro Bold Italic\', sans-serif',
        '--bold_condensed_italic_font_family' : '\'Myriad Pro Bold Condensed Italic\', sans-serif',
        '--wrapper_background_color' : '#0374c1',
        '--main_container_background_color' : '#fdfdfd',
        '--step_bar_border_color' : '#789EF4',
        '--step_bar_icon_background_color' : '#D5E1FF',
        '--step_bar_icon_text_color' : '#C2C4E0',
        '--step_bar_incomplete_progress_color' : '#D5E1FF',
        '--step_bar_active_icon_background_color' : '#6AB443',
        '--step_bar_active_icon_text_color' : '#6AB443',
        '--step_bar_complete_progress_color' : '#6AB443',
        '--step_bar_icon_color' : '#ffffff',
        '--agreement_box_background_color' : '#F8FDFF',
        '--agreement_box_border_color' : '#99B2D0',
        '--every_step_color' : '#3760A7',
        '--bar_background_color': '#0e72b5',
        '--dashboard-strip': '#345fae',
        '--navbar':"url('/assets/img/PCE_hamburger.svg')",
        '--navclose':"url('/assets/img/PCE_close.svg')",
        '--jambotron_bg_color':'',
        '--jambotron_bg_img':"url(/assets/img/background-line-art-futuristic-design-geometric-techn@2x.png) no-repeat 0% 0%"
    }
};
