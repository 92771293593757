import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Subscription } from 'rxjs';
import { ProjectCount } from 'src/app/models/projectcount';
import { TabView } from 'primeng/tabview';

@Component({
  selector: 'app-program-dashboard',
  templateUrl: './program-dashboard.component.html',
  styleUrls: ['./program-dashboard.component.scss']
})
export class ProgramDashboardComponent implements OnInit, OnDestroy {
  dashBoardHeaderContent: any;
  projectCountData: any;
  projectCountoptions: any;
  forecastData: any;
  forecastoptions: any;
  projectCountStageIcons: any = {};
  projectCountHeader: string;
  incentiveBugetHeader: string;
  installationForecastHeader: string;
  installationLocationsHeader: string;
  incentiveBudgetData: any;
  budgetData: any = [];
  projectCountByStage: ProjectCount[] = [];
  forecastInstallation: any[] = [];
  locationOptions: any;
  locationOverlays: any[] = [];
  tabVisibility: any;

  map: google.maps.Map;


  projectCountSubscription: Subscription;
  incentiveBudgetSubscription: Subscription;
  installationForecastSubscription: Subscription;
  installationLocationsSubscription: Subscription;

  @ViewChild(TabView , { static: true }) tabView: TabView;

  constructor(private dashboardService: DashboardService) { }

   ngOnInit() {
        this.dashBoardHeaderContent = AppConfigService.settings.dashboard.dashboardHeader;
        this.tabVisibility = AppConfigService.settings.dashboard.tabsVisibility;
        this.projectCountHeader = "Project count by stage";
        this.incentiveBugetHeader = "Incentive budget status";
        this.installationForecastHeader = "Installation forecast";
        this.installationLocationsHeader = "Installation locations";
        this.loadProjectCountByStage();
    }

    onTabChange(index: number) {
      let tabHeader = this.tabView.tabs[index].header;
      if(tabHeader == this.projectCountHeader)  {
          this.loadProjectCountByStage();
      }
      else if(tabHeader == this.incentiveBugetHeader) {
          this.loadIncentiveBudgetStatus();
      }
      else if(tabHeader == this.installationForecastHeader) {
          this.loadInstallationForecast();
      }
      else if(tabHeader == this.installationLocationsHeader) {
          this.loadInstallationLocations();
      }
    }

    loadProjectCountByStage() {
     
      this.projectCountStageIcons = AppConfigService.settings.dashboard.projectCountStageIcons;

      this.projectCountSubscription = this.dashboardService.getProjectCountByStage().subscribe(res => {

      this.projectCountByStage = res;

      if(this.projectCountByStage.length >= 1) {
        let projectCount = res.map((stage) => {
        let selectedRecord = this.projectCountByStage.find(x => x.stageName == stage.stageName);
        let tempStageName = stage.stageName.replace(/ +/g, "");
        selectedRecord.stageIcon = this.projectCountStageIcons[tempStageName];
        return stage.projectCount;
      })

      let stageIcons = Object.keys(this.projectCountStageIcons).map(e => {
          return this.projectCountStageIcons[e]
      })

     // let stageIcons = Object.values(this.projectCountStageIcons);

      this.projectCountData = {
        labels: stageIcons, //[this.projectCountStageIcons.Application, this.projectCountStageIcons.Qualification, this.projectCountStageIcons.SiteEvaluation, this.projectCountStageIcons.SolutionSelection, this.projectCountStageIcons.Bidding, this.projectCountStageIcons.Installation, this.projectCountStageIcons.Verification, this.projectCountStageIcons.Payment, this.projectCountStageIcons.Complete, this.projectCountStageIcons.Cancelled], 
        datasets: [
            {
                backgroundColor: 'lightgray',
                borderColor: '#1E88E5',
                data: projectCount 
            }
        ]
      }
      this.projectCountoptions = {
      
        legend: {
          display: false
        },
        responsive: true,
        tooltips: {
          mode: 'index',
          enabled: true,
          callbacks: {
            title: function(tooltipItems, data) {
             let index = tooltipItems[0].index;
             return tooltipItems[0].xLabel = tooltipItems[0].label = res[index].stageName;
            }
          }
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            categoryPercentage: 0.4,
            barPercentage: 0.4,
            stacked: true,
            ticks: {
              fontFamily: 'FontAwesome' ,
              fontSize: 23,
              fontColor: 'gray',
            },
            gridLines : {
              display : false
          }
          }],
          yAxes: [{
            gridLines: {
              drawBorder: false,
            },
            ticks: {
              display: false,
              beginAtZero: true
          }
          }]
        }
      };
    }
  });
    }

    loadIncentiveBudgetStatus(){
      this.incentiveBudgetSubscription = this.dashboardService.getIncentiveBudgetStatus().subscribe(res => {
      this.budgetData = res;

      this.incentiveBudgetData = {
        labels: ["Total paid", "Total reserved", "Total available funds"],
        datasets: [
          {
            data: [res[2].Value, res[1].Value, res[3].Value],
            backgroundColor: ['#4472C4', '#A5A5A5', '#5B9BD5']
          }
        ]
      }
    });
    }

    loadInstallationForecast() {
       
        this.installationForecastSubscription = this.dashboardService.getInstallationForecast().subscribe(res => {

        let months = res.map((item) => {
          return item.month;
        })

        let totalPorts = res.map((item) => {
          return item.totalPorts;
        })

       this.forecastInstallation = res;

        this.forecastData = {
          labels:  months, 
          datasets: [
              {
                  backgroundColor: 'lightgray',
                  borderColor: '#1E88E5',
                  data: totalPorts 
              }
          ]
        }
          this.forecastoptions = {
            legend: {
              display: false
            },
            responsive: true,
            showTooltips: false,
            maintainAspectRatio: false,
            scales: {
              xAxes: [{
                categoryPercentage: 0.3,
                barPercentage: 0.3,
                stacked: true,
                ticks: {
                  fontFamily: 'Myriad Pro Regular' ,
                  fontSize: 14,
                  fontColor: 'gray',
                },
                gridLines : {
                  display : false
              }
              }],
              yAxes: [{
                gridLines: {
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                  beginAtZero: true
              }
              }]
            },
          };
        });
    }

    setMap(event) {
      this.map = event.map;
    }

    loadInstallationLocations() {
     
      this.installationLocationsSubscription =  this.dashboardService.getInstallationLocations().subscribe(res => {
      
      this.locationOptions = {
              zoom: 12
            };

      let bounds = new google.maps.LatLngBounds();
      this.locationOverlays = res.map((location) => {
        return new google.maps.Marker(
                                  {
                                    position: 
                                      {
                                        lat: location.site.geolocationLatitude, 
                                        lng: location.site.geolocationLongitude
                                      }, 
                                     
                                    title: location.site.fullAddress,
                                    icon: {
                                      path: google.maps.SymbolPath.CIRCLE,
                                      scale: 5,
                                      fillColor: location.projectStage == "Complete" ? "#00FF00" : "#696969",
                                      fillOpacity: 1,
                                      strokeWeight: 1,
                                      strokeColor: "#A9A9A9"
                                    }
                                  }
                                )
      });

        this.locationOverlays.forEach(marker => {
            bounds.extend(marker.getPosition());
        });

        setTimeout(()=> {
            this.map.fitBounds(bounds); 
        }, 500);

        });
    }

    ngOnDestroy() {
      if(this.projectCountSubscription) {
        this.projectCountSubscription.unsubscribe();
      }

      if(this.incentiveBudgetSubscription) {
        this.incentiveBudgetSubscription.unsubscribe();
      }

      if(this.installationForecastSubscription) {
        this.installationForecastSubscription.unsubscribe();
      }

      if(this.installationLocationsSubscription) {
        this.installationLocationsSubscription.unsubscribe();
      }
    }
}
