import { Component, OnInit, ViewEncapsulation, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { saveAs } from 'file-saver/FileSaver';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/shared/models/project';
import { FormBuilder } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssistYouComponent } from '../assist-you/assist-you.component';

@Component({
  selector: 'app-view-solution',
  providers: [DialogService],
  templateUrl: './view-solution.component.html',
  styleUrls: ['./view-solution.component.scss'],
  styles: [`
  .ui-steps .ui-steps-item {
    width: 20%;
  }

  .ui-steps.steps-custom {
      margin-bottom: 30px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
      padding: 0 1em;
      overflow: visible;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
      background-color: #0081c2;
      color: #FFFFFF;
      display: inline-block;
      width: 36px;
      border-radius: 50%;
      margin-top: -14px;
      margin-bottom: 10px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
      color: #555555;
  }
`],
encapsulation: ViewEncapsulation.None
})
export class ViewSolutionComponent implements OnInit, OnDestroy {
  displayNames: any;
  displayText: any;
  textLanguage: any;
  displayNameKeys: string[];
  id: string;
  projectId: string;
  protectedProjectId: string;
  selectSolutionError = false;
  paramIds: any;

  constructor(private fb: FormBuilder,
              private dashboardService: DashboardService,
              private toasterService: ToasterService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public dialogService: DialogService) { }
  items: any;
  activeIndex: number;
  routeSubscription: Subscription;
  reviewApplicationSubscription: Subscription;
  ref: DynamicDialogRef;
  solutionSelected: string;
  solutionData: any[] = [];

  ngOnInit() {

    this.displayNames = AppConfigService.settings.generalInformationPage.generalInfo;
    this.displayText = AppConfigService.settings.generalInformationPage.textLanguage;
    this.displayNameKeys = Object.keys(this.displayNames);
    this.textLanguage = AppConfigService.settings.customerSolutionPage.textLanguage;

    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.id = atob(params.id);
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
    });

    this.reviewApplicationSubscription = this.dashboardService.getReviewApplicationByProjectId(this.protectedProjectId).subscribe(res => {
      if (res.solutions != null) {
        this.solutionData = res.solutions.records;
      }
    });

    this.activeIndex = 1;
    this.intializeCustomSteps();


  }


  intializeCustomSteps() {

    this.items = [
      {
        label: 'Onsite Evaluation',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        label: 'Scope Selection',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        label: 'Request Bids',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-list-ul'
      },
      {
        label: 'Contract Details',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-shield'
      },
      {
        icon: 'fa fa-check',
        label: this.displayText.customStep5,
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  downloadDocument(solution: any) {
    if (solution.attachment != undefined) {

    this.dashboardService.getDownloadDocument(solution.attachment)
    .subscribe(
              success => {
                saveAs(success, solution.attachment.name);
              },
              (error) => {
                  this.toasterService.showError('File not found');
              }
          );

    } else {
      this.toasterService.showError('File not found');
    }
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  reserveIncentive() {
    if (this.solutionSelected) {
      const payload = {
        'projectStage': 'Bidding',
        'projectStatus': 'Solution Selected',
        'isAcceptedSiteHostAgreement' : true,
        'selectedSolutionId' : this.solutionSelected
      };

      this.reviewApplicationSubscription = this.dashboardService.ReserveIncentiveOffer(this.id, payload).subscribe(response => {
        if (response.success) {
          this.router.navigate(['incentive-reserved', btoa(this.id) , btoa(this.projectId), btoa(this.protectedProjectId)]);
        }
      });
    } else {
      this.selectSolutionError = true;
    }
  }

  getSolutionSelected(event) {
    this.solutionSelected = event;
  }

  show() {
    this.ref = this.dialogService.open(AssistYouComponent, {
      data: {
        projectId : this.projectId,
        protectedProjectId : this.protectedProjectId
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });
  }

  ngOnDestroy() {
    if (this.reviewApplicationSubscription) {
      this.reviewApplicationSubscription.unsubscribe();
    }

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}
