export const CLIENT_CONFIG = {
    // clientA: {
    //     logoUrl: 'dsssssssss',
    //     companyName: 'Tucson Electric Power',
    //     id: 1,
    //     name: 'Leanne Graham',
    //     username: 'Bret',
    //     email: 'Sincere@april.biz',
    //     phone: '1-770-736-8031 x56442',
    //     website: 'hildegard.org'
    // }
    apis: {
        subdomain: '/TEPEV',
        baseApi: 'https://c90068daas002.azurewebsites.net',
        eligibilityAddress: '/EligiblityCheck/Address',
        submitWorkflowFormData: '/ProgramEnrollment',
        verifyServiceNumber: '/EligiblityCheck/ServiceNumber',
        verifyContactAddress: '/contact'
    },
    errors: {
        clientSideError: 'An error occurred.',
        serverErrors: 'Internal server error.Please try again later.',
    }
};
export const STEP_1 = [
    {
        question: '1 Which based describe the proprty type,in terms of role in decision making ?',
        description: '',
        description2: '',
        controlName: 'ownerType',
        placeholder: '',
        controlType: 'radio',
        hidden: false,
        options: [{
            optionName: 'Propert manager or owner',
            value: 'Propert manager or owner',
            img: '/assets/img/contractor.png',
            hidden: false
        }, {
            optionName: 'Tenant or employee at property',
            value: 'Tenant or employee at property',
            img: '/assets/img/contractor.png',
            hidden: false
        }, {
            optionName: 'Contractor for property',
            value: 'Contractor for property',
            img: '/assets/img/contractor.png',
            hidden: false
        }],
        validators: {
            required: true
        }
    },
    {
        question: '2.Your Contact Information?',
        description: '(Required fields)',
        controlName: 'contactInfo',
        placeholder: '',
        controlType: 'contactInfo',
        hidden: false,
        controls: [
            {
                controlName: 'businessName',
                controlType: 'text',
                valueType: 'text',
                placeholder: 'Name of your company or organization',
                hidden: false,
                validators: {
                    required: true,
                    minlength: 5
                }
            },
            {
                namePlace: [
                    {
                        controlName: 'FirstName',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        hidden: false,
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'LastName',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        hidden: false,
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                ]
            },
            {
                controlName: 'email',
                controlType: 'text',
                valueType: 'text',
                placeholder: 'Enter username',
                hidden: false,
                validators: {
                    required: true,
                    minlength: 5
                }
            },
            {
                phoneAndTimeSpace: [
                    {
                        controlName: 'Phone',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        hidden: false,
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'ext',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        hidden: false,
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    }
                ]


            }

        ],
        validators: {
            required: true
        }
    },
    {
        question: '3.How did you hear about the program?',
        description: '(Optional)',
        controlName: 'programReach',
        placeholder: 'Select any',
        controlType: 'select',
        hidden: false,
        options: [{
            optionName: 'Office',
            value: 'office',
            hidden: false
        }, {
            optionName: 'Advertisement',
            value: 'add',
            hidden: false
        }],
        validators: {
            required: true
        }
    },
];
export const STEP_2 = [
    {
        question: '4 What is the property type ?',
        description: '(For mixed-use properties,select the type that best fits the primary use of the space)',
        controlName: 'properType',
        placeholder: '',
        controlType: 'radio',
        options: [{
            optionName: 'Commercial business',
            value: 'Commercial business',
            img: '/assets/img/contractor.png'
        }, {
            optionName: 'Government',
            value: 'Government',
            img: '/assets/img/contractor.png'
        },
        {
            optionName: 'Multifamily residential<br/>(more than four units)',
            value: 'Multifamily',
            img: '/assets/img/contractor.png'
        },
        {
            optionName: 'Nonprofit',
            value: 'Nonprofit',
            img: '/assets/img/contractor.png'
        }
        ],
        validators: {
            required: true
        }
    },
    {
        question: '5 How brodly will the chargers be accessible ?',
        description: '(select all that apply)',
        controlName: 'accessiblilities',
        placeholder: '',
        type: 'formGroup',
        controlType: 'checkboxgroup',
        controls: [{
            controlName: 'General Public',
            optionName: 'General Public',
            value: true,
            checked: false,
            controlType: 'checkbox'
        }, {
            controlName: 'Customers',
            optionName: 'Customers',
            value: false,
            checked: false,
            controlType: 'checkbox'
        },
        {
            controlName: 'Employee,tenants and guests',
            optionName: 'Employee,tenants and guests',
            value: 'Employee,tenants and guests',
            checked: false,
            controlType: 'checkbox'
        },
        {
            controlName: 'Fleet vehicles',
            optionName: 'Fleet vehicles',
            value: 'Nonprofit',
            checked: false,
            controlType: 'checkbox'
        },
        {
            controlName: 'Assigned individuals',
            optionName: 'Assigned individuals',
            value: 'Assigned individuals',
            checked: true,
            controlType: 'checkbox'
        }
        ],
        validators: {
            required: true
        }
    },
    {
        question: '6 Pleasde estimate the total number of parking spaces at the property ?',
        description: '(in terms of role in decision making)',
        controlName: 'parkingSpaceQuantity',
        controlType: 'text',
        valueType: 'text',
        placeholder: 'Enter username',
        validators: {
            required: true,
            minlength: 5
        }
    },
    {
        question: '7.How many chargers are you inetrested in installing?',
        description: '(Not sure about the different charger type?)',
        controlName: 'chargersInterested',
        type: 'formGroup',
        placeholder: '',
        controlType: 'numbergroup',
        controls: [
            {
                controlName: 'Level 2',
                controlType: 'number',
                valueType: 'number',
                placeholder: 'Select Quantity',
                min: 1,
                max: 5,
                validators: {
                    required: true,
                    min: 1,
                    max: 5,
                }
            },
            {
                controlName: 'DCFC',
                controlType: 'number',
                valueType: 'number',
                min: 1,
                max: 5,
                placeholder: 'Select Quantity',
                validators: {
                    required: true,
                    min: 1,
                    max: 5,
                }
            },

        ],
        validators: {
            required: true
        }
    },
    {
        question: '2.Your Contact Information?',
        description: '(Required fields)',
        controlName: 'contactInfo',
        placeholder: '',
        controlType: 'contactInfo',
        controls: [
            {
                controlName: 'businessName',
                controlType: 'text',
                valueType: 'text',
                placeholder: 'Name of your company or organization',
                validators: {
                    required: true,
                    minlength: 5
                }
            },
            {
                namePlace: [
                    {
                        controlName: 'FirstName',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'LastName',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                ]
            },
            {
                controlName: 'email',
                controlType: 'text',
                valueType: 'text',
                placeholder: 'Enter username',
                validators: {
                    required: true,
                    minlength: 5
                }
            },
            {
                phoneAndTimeSpace: [
                    {
                        controlName: 'Phone',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'ext',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    }
                ]


            }

        ],
        validators: {
            required: true
        }
    },
    {
        question: '3.How did you hear about the program?',
        description: '(Optional)',
        controlName: 'programReach',
        placeholder: 'Select any',
        controlType: 'select',
        options: [{
            optionName: 'Office',
            value: 'office'
        }, {
            optionName: 'Advertisement',
            value: 'add'
        }],
        validators: {
            required: true
        }
    },
    {
        question: '8 Which phase of the project are you in ?',
        description: '(For mixed-use properties,select the type that best fits the primary use of the space)',
        controlName: 'phaseType',
        placeholder: '',
        controlType: 'radio',
        options: [{
            optionName: 'Exploring options',
            value: 'Exploring options',
            img: '/assets/img/contractor.png'
        }, {
            optionName: 'Developing site plan',
            value: 'Developing site plan',
            img: '/assets/img/contractor.png'
        },
        {
            optionName: 'Getting bids',
            value: 'Getting bids',
            img: '/assets/img/contractor.png'
        },
        {
            optionName: 'Ready to install',
            value: 'Ready to install',
            img: '/assets/img/contractor.png'
        }],
        validators: {
            required: true
        }
    },
    {
        question: '9 Additional Comments',
        description: '(Any additional information you would like to add about the property or projet)',
        controlName: 'Comments',
        controlType: 'textarea',
        rows: 5,
        valueType: 'text',
        placeholder: 'Enter comments',
        validators: {
            required: true,
            minlength: 10
        }
    },
    {
        question: '10 I agree to the program"s Terms and Conditions ?',
        description: '',
        controlName: 'agreement',
        placeholder: '',
        controlType: 'checkbox',
        options: [{
            optionName: 'I agree to the program"s Terms and Conditions ?',
            value: true,
            controlName: 'agreement',
            checked: false,
            controlType: 'checkbox'
        }],
        validators: {
            required: true
        }
    },
];
export const MockForm = [
    {
        question: 'Which describes you most accurately?',
        description: '',
        controlName: 'Vehicle you own',
        placeholder: 'Select vehicle',
        controlType: 'radio',
        options: [{
            optionName: 'I have a bike',
            value: 'bike'
        }, {
            optionName: 'I have a car',
            value: 'car'
        }],
        validators: {
            required: true
        }
    },
    {
        question: 'Which based describe the proprty type ?',
        description: '(in terms of role in decision making)',
        controlName: 'Username',
        controlType: 'text',
        valueType: 'text',
        placeholder: 'Enter username',
        validators: {
            required: true,
            minlength: 5
        }
    },
    {
        question: 'Which describes you most accurately?',
        description: '(in terms of role in decision making)',
        controlName: 'Telephone',
        placeholder: 'Enter Phone',
        valueType: 'tel',
        controlType: 'text',
        validators: {
            required: true,
            minlength: 7,
            maxlength: 10
        }
    },
    {
        question: 'Which describes you most accurately?',
        description: '(in terms of role in decision making)',
        controlName: 'Email',
        valueType: 'email',
        placeholder: 'Enter email',
        controlType: 'text',
        validators: {
            required: true
        }
    },
    {
        question: 'Which describes you most accurately?',
        description: '(in terms of role in decision making)',
        controlName: 'Gender',
        placeholder: 'Select gender',
        controlType: 'select',
        options: [{
            optionName: 'Male',
            value: 'male'
        }, {
            optionName: 'Female',
            value: 'female'
        }],
        validators: {
            required: true
        }
    },
    {
        question: 'Additional Comments',
        description: '(Any additional information you would like to add about the property or projet)',
        controlName: 'Comments',
        controlType: 'textarea',
        rows: 5,
        valueType: 'text',
        placeholder: 'Enter comments',
        validators: {
            required: true,
            minlength: 10
        }
    },
    {
        question: 'Which describes you most accurately?',
        description: '',
        controlName: 'I agree to the Program terms and conditions',
        placeholder: 'Select term and conditions',
        controlType: 'checkbox',
        options: [{
            optionName: 'I have a bike',
            value: 'bike'
        }],
        validators: {
            required: true
        }
    },
    {
        question: 'Your Contact Information?',
        description: '(Required fields)',
        controlName: 'contactInfo',
        placeholder: '',
        controlType: 'contactInfo',
        controls: [
            {
                controlName: 'businessName',
                controlType: 'text',
                valueType: 'text',
                placeholder: 'Name of your company or organization',
                validators: {
                    required: true,
                    minlength: 5
                }
            },
            {
                namePlace: [
                    {
                        controlName: 'FirstName',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'LastName',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                ]
            },
            {
                controlName: 'email',
                controlType: 'text',
                valueType: 'text',
                placeholder: 'Enter username',
                validators: {
                    required: true,
                    minlength: 5
                }
            },
            {
                phoneAndTimeSpace: [
                    {
                        controlName: 'Phone',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'ext',
                        controlType: 'text',
                        valueType: 'text',
                        placeholder: 'Enter username',
                        validators: {
                            required: true,
                            minlength: 5
                        }
                    },
                    {
                        controlName: 'Best time to call',
                        controlType: 'select',
                        valueType: '',
                        placeholder: 'Enter username',
                        options: [{
                            optionName: 'Male',
                            value: 'male'
                        }, {
                            optionName: 'Female',
                            value: 'female'
                        }],
                        validators: {
                            required: true
                        }
                    }
                ]


            }

        ],
        validators: {
            required: true
        }
    }
];



export const data = [{
    Question: 'Which describes you most accurately, in terms of role in decision making?',
    Description: '',
    Controltype: 'radio',
    Control: [{
        Radio: {
            id: 'idVal1',
            label: '',
            validator: { required: true },
            options: [{ name: 'Owner', value: 'Owner', image: '' },
            { name: 'Tenant', value: 'Tenant', image: '' },
            { name: 'Contractor', value: 'Contractor', image: '' }]
        }
    }]
},
{
    Question: 'How many chargers are you interested in installing?',
    Description: '',
    Controltype: 'Select',
    Control: [{
        Select: {
            id: 'idVal2',
            label: 'Level2',
            validator: { Required: true },
            options:
                [{ name: 0, value: 0, image: '' },
                { name: 1, value: 1, image: '' },
                { name: 2, value: 2, image: '' }
                ]
        }
    },
    {
        Select: {
            id: 'idVal3',
            label: 'DCFC',
            validator: { required: true },
            options:
                [{ name: 0, value: 0, image: '' },
                { name: 1, value: 1, image: '' },
                { name: 2, value: 2, image: '' }
                ]
        }
    }]
}];
