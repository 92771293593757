import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AssistYouComponent } from '../assist-you/assist-you.component';
import { ContractorBiddingService } from 'src/app/services/contractor-bidding/contractor-bidding.service';
import { Contractor } from 'src/app/shared/models/contractor';

@Component({
  selector: 'app-contractor-bidding',
  templateUrl: './contractor-bidding.component.html',
  styleUrls: ['./contractor-bidding.component.scss'],
})
export class ContractorBiddingComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private router : Router,
              private activatedRoute: ActivatedRoute,
              public dialogService: DialogService,
              private biddingService: ContractorBiddingService) { }

  
  activeIndex: number;
  items:any;
  displayText: any;
  num: any;
  contractors: Contractor[] = [];
  allContractors: Contractor[] = [];
  selectedContractors: any[] = [];
  filtersForm = this.fb.group({
    search : [''],
    sortBy: ['0'],
  });
  
  ref: DynamicDialogRef;
  projectId: string;
  protectedProjectId: string;
  id: string;
  noRecordsText : string;
  routeSubscription: Subscription;
  biddingSubscription: Subscription;

  ngOnInit() {
    this.displayText = AppConfigService.settings.contractBiddingDetails;
    this.noRecordsText = "";
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
      this.id = atob(params.id);
      });
    this.activeIndex = 2;
    this.intializeCustomSteps();
    this.num = [1,2,3,4,5,6,7,8,9,10];
    this.GetContractorsByProject(this.protectedProjectId, "*");
  }

  
  intializeCustomSteps() {

    this.items = [
      {
        label: 'Onsite Evaluation',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        label: 'Scope Selection',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        label: 'Request Bids',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-list-ul'
      },
      {
        label: 'Contract Details',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-shield'
      },
      {
        icon: 'fa fa-check',
        label: this.displayText.customStep5,
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  search(){
    let searchText = this.filtersForm.value.search.toLowerCase();
    this.contractors = this.allContractors.filter(x => {
        return (x.name.toLowerCase().startsWith(searchText)) || (x.billingCity.toLowerCase().startsWith(searchText));
    });
    this.BindContractors();
  }

  GetContractors(search: string) {
    this.biddingSubscription = this.biddingService.getContractors(search).subscribe(response =>{
      if(response){
       this.contractors = this.allContractors = response.filter(x => x.name != null && x.billingCity != null);
       this.BindContractors();
      }     
    });
  }

  GetContractorsByProject(protectedProjectId: string, search: string) {
    this.biddingSubscription = this.biddingService.getContractorsByProject(protectedProjectId, search).subscribe(response => {
      if (response) {
        this.contractors = this.allContractors = response.filter(x => x.name != null && x.billingCity != null);
        this.BindContractors();
      }
    });
  }

  BindContractors() {
    this.contractors.map(contractor => {
      let foundContractor = this.selectedContractors.find(fcontractor => fcontractor.id == contractor.id )
      if(foundContractor){
        contractor.isContractorAdded = true;
      }
      else{
        contractor.isContractorAdded = false;
      }
    });
   this.noRecordsText = this.displayText.noRecordsText;
   this.sort();
  }
  
  sort(){
    if(this.contractors){
      if(this.filtersForm.value.sortBy == 0){
        this.contractors.sort(function (a, b) {
          if(a.name != null && b.name != null)
              return a.name.localeCompare(b.name);
        });
      }

      if(this.filtersForm.value.sortBy == 1){
        this.contractors.sort(function (a, b) {
          if (a.billingCity)            
            return  b ? a.billingCity.localeCompare(b.billingCity) : -1;
          else if (b.billingCity) 
            return a ? b.billingCity.localeCompare(a.billingCity) : 1;
        });
      }
    }
  }

  emailContractor(contractor){
    contractor.isContractorAdded = !contractor.isContractorAdded;
    if(contractor.isContractorAdded) {
      let tempContractor = this.allContractors.filter(x => x.name ==  contractor.name && x.primaryContactEmail == contractor.primaryContactEmail);
      this.selectedContractors = [...this.selectedContractors, ...tempContractor];
    }
    else {
        this.selectedContractors = this.selectedContractors.filter(contractor => contractor.isContractorAdded === true);
        let tempContractor = this.allContractors.find(x => x.name ==  contractor.name && x.primaryContactEmail == contractor.primaryContactEmail);
        tempContractor.isContractorAdded = false;
    }
  }

  goBack(){
    this.router.navigate(['/next-steps',btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  nextStep(){
    let payload = {
      "projectId": this.projectId,
      "bidContractors": this.selectedContractors,
      "protectedProjectId": this.protectedProjectId
    }
    this.biddingSubscription = this.biddingService.requestBids(this.id, payload).subscribe(response =>{
      if(response){
        this.router.navigate(['/updated-contractor-bids',btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
      }
    });
  }
  show(){
    this.ref = this.dialogService.open(AssistYouComponent, {
      data: {
        projectId : this.projectId,
        protectedProjectId : this.protectedProjectId
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });
  }

}
