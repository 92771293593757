import { Injectable } from '@angular/core';
import { StaticContactInformationComponent } from '../../components/static-contact-information/static-contact-information.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('ContactInformationDialogService');
@Injectable({
  providedIn: 'root'
})
export class ContactInformationDialogService {

  constructor(
    public dialogService: DialogService,
  ) { }

  show(flag: boolean, from: string) {
    const ref = this.dialogService.open(StaticContactInformationComponent, {
      data: {
        id: '51gF3',
        newtext: 'sdddddddddd',
        iconWithPropertyText: flag,
        fromWhere: from
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });

    ref.onClose.subscribe((status) => {
      log.debug('closed dialog in service', status);
      if (status) {
        // this.enableThankyou.emit(status);
      }

      // if (car) {
      //   this.messageService.add({ severity: 'info', summary: 'Car Selected', detail: 'Vin:' + car.vin });
      // }
    });
  }
}
