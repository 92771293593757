import { Component, OnInit } from '@angular/core';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Component({
  selector: 'app-goodnews',
  templateUrl: './goodnews.component.html',
  styleUrls: ['./goodnews.component.scss']
})
export class GoodnewsComponent implements OnInit {
  stepNumber: number;
  goodNewsSection = AppConfigService.settings.workFlow.goodNews;
  constructor(private workflowService: WorkflowService) { }

  ngOnInit() {
    this.workflowService.getWorkFlowStatus().subscribe((workFlow) => {
      this.stepNumber = workFlow.step;
    });
  }

}
