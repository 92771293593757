import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/http/http.service';


@Injectable({
  providedIn: 'root'
})
export class ContractorService {

  constructor(private http: HttpClient, private httpService: HttpService) { }
   upload(formData: FormData, id: string, documentType: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}/file/upload/${id}/${documentType}`, formData);
  } 


  saveContractorDetails(id,payload): Observable<any> {
    return this.httpService.put('/Project/SaveContractDetails/'+id, payload);
  }

  getContractors(): Observable<any> {
      return this.httpService.get('/Contractor')
  }

  removeFile(fileKey: string) {
    return this.httpService.delete('/file/delete?fileKey=' + fileKey);
  }
    getContactInfo(email: string) {
      return this.httpService.get('/ProgramEnrollment/' + email)
    }
}
