import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() input: any;
  @Input() group: FormGroup;
  @Input() option: any;
  constructor(private dom: DomSanitizer) { }

  ngOnInit() {
  }
  onChange($event) {
    this.group.get(this.option.controlName).setValue($event.target.checked);
  }
  getSafeHTML(html) {
    return this.dom.bypassSecurityTrustHtml(html);
  }
  download(optionName: string, pdfUrl) {
    if (optionName.includes('I agree to the')) {
      window.open(pdfUrl, '_blank');
    }
  }
}
