import { HttpInterceptor, HttpProgressEvent, HttpSentEvent, HttpHeaderResponse, HttpUserEvent, HttpRequest, HttpResponse,  HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private tenantService: TenantService, 
                private authService: MsalService,  
                private router: Router) { }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | HttpEvent<any>> {
        const host = this.tenantService.getHostname();
        let reqHeaders: HttpHeaders;
        if (req.url.indexOf('json') > -1) {
            reqHeaders = new HttpHeaders({
                            Accept: 'application/json',
                            'Content-Type' : 'application/json',
                            'X-Tenant-Host' : host
                         });
        } 
        else if(req.url.indexOf('upload') > -1)   {
                        reqHeaders = new HttpHeaders({
                            'X-Tenant-Host' : host,
                             Accept: '*/*',
                             Authorization: 'Bearer ' +  sessionStorage.getItem('msal.idtoken'),
                         });
        }  
        else {
            if(sessionStorage.getItem('msal.idtoken')) {
                    reqHeaders = new HttpHeaders({
                                    Accept: 'application/json',
                                    'Content-Type' : 'application/json',
                                    'X-Tenant-Host' : host,
                                    Authorization: 'Bearer ' +  sessionStorage.getItem('msal.idtoken'),
                                    'Cache-Control': 'no-cache',
                                    Pragma: 'no-cache',
                                    Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
                                    'If-Modified-Since': '0'
                                });
                    }
                    else {
                        reqHeaders = new HttpHeaders({
                            Accept: 'application/json',
                            'Content-Type' : 'application/json',
                            'X-Tenant-Host' : host,
                            'Cache-Control': 'no-cache',
                            Pragma: 'no-cache',
                            Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
                            'If-Modified-Since': '0'
                        });
                    }
        }
        const apiReq = req.clone({
            headers: reqHeaders
        });
        return next.handle(apiReq).do((event: HttpEvent<any>) => {     
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.authService.logout();
              this.router.navigate(['/home']);
            }
          }
        });;
    }
}
