import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { AssistYouComponent } from '../assist-you/assist-you.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';


@Component({
  selector: 'app-next-steps',
  templateUrl: './next-steps.component.html',
  styleUrls: ['./next-steps.component.scss']
})
export class NextStepsComponent implements OnInit {

  id: string;
  projectId: string;
  protectedProjectId: string;
  displayText : any;
  projectDetails: any;
  address: string;
  name: string;
  phone: string;
  email: string;
  isSiteEvaluationComplete: boolean;
  viewProjectSolutionButton: boolean;
  isSelectProjectScopeComplete: boolean;
  isIncentiveReservedDateComplete: boolean;
  isInstallationComplete: boolean;
  isContractorBidInProgress: boolean;
  isContractorComplete: boolean;
  isSolutionStatusApproved: boolean;

  applicationSubscription: Subscription;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogService,
    private dashboardService: DashboardService,) { }

  routeSubscription: Subscription;
  ref: DynamicDialogRef;

  ngOnInit() {
    this.displayText = AppConfigService.settings.nextStepsPage;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.id = atob(params.id);
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
    });

    this.applicationSubscription = this.dashboardService.getReviewApplicationByProjectId(this.protectedProjectId).subscribe(res => {

    this.projectDetails = res;
    this.address = this.projectDetails.site.fullAddress;
    this.name = `${this.projectDetails.projectContact.firstName} ${this.projectDetails.projectContact.lastName}`;
    this.phone = this.projectDetails.projectContact.phone;
    this.email = this.projectDetails.projectContact.email;
    this.projectId = this.projectDetails.projectId;
    this.protectedProjectId = this.projectDetails.protectedProjectId;

    this.viewProjectSolutionButton = this.projectDetails.incentiveReservedDate != null ? true : false;

    this.isSiteEvaluationComplete = this.projectDetails.evaluationDate != null ? true : false;

    this.isSelectProjectScopeComplete = (this.projectDetails.evaluationDate != null && this.projectDetails.incentiveReservedDate != null) ? true : false;

    this.isContractorBidInProgress = this.isSelectProjectScopeComplete && this.projectDetails.contractorBidsDate != null && this.projectDetails.contractorSelectedDate == null;
    this.isContractorComplete = this.isSelectProjectScopeComplete && this.projectDetails.contractorBidsDate != null && this.projectDetails.contractorSelectedDate != null;
    
    this.isInstallationComplete = (this.isContractorComplete  && this.projectDetails.installationCompletedDate != null) ? true : false;

    this.isSolutionStatusApproved = this.projectDetails.solutionStatus == "Approved" ? true : false;
    });
  }

  chooseSolution(){
    this.router.navigate(['/view-solution', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

  showContractorDetails(){
    this.router.navigate(['/contract-details', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

  submitIncentiveApplication() {
    window.open('https://peninsulacleanenergy.formstack.com/forms/ev_charger_incentives_application', '_blank');
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  inviteContractors(){
    this.router.navigate(['/contractor-bidding', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }
  claimIncentive(){
    this.router.navigate(['/claim-incentive', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }
  viewProjectSolution(){
    this.router.navigate(['/project-solution', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

  show() {
    this.ref = this.dialogService.open(AssistYouComponent, {
      data: {
        id: this.id,
        projectId: this.projectId,
        protectedProjectId: this.protectedProjectId
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });
  }
}
