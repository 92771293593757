import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html'
})
export class ContactInformationComponent implements OnInit {

  @Input() input: any;
  @Input() group: FormGroup;
  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }
}
