import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { GeneralInfo } from 'src/app/shared/models/generalinfo';
import { formatDate } from '@angular/common';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Component({
  selector: 'app-project-solution',
  templateUrl: './project-solution.component.html',
  styleUrls: ['./project-solution.component.scss']
})
export class ProjectSolutionComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private dashboardService: DashboardService,
              private toasterService: ToasterService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public dialogService: DialogService) { }
  id: string;
  projectId: string;
  protectedProjectId: string;
  solutionData: any[] = [];
  generalInfo: GeneralInfo;
  displayNames: any;
  displayText: any;
  displayNameKeys: any;
  activeIndex = 0;
  statusName: string;
  approvedDeniedBy: string;
  approvedDeniedDate: string;
  solutionStatus: string;
  solutionStatusApproved:string="Approved";
  solutionStatusDenied:string="Denied";
  projectAttachments: any;
  routeSubscription: Subscription;
  reviewApplicationSubscription: Subscription;

  ngOnInit() {
    this.displayText = AppConfigService.settings.contractBiddingDetails;
    this.intializeGeneralInfo();
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.id = atob(params.id);
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
    });

    this.reviewApplicationSubscription = this.dashboardService.getReviewApplicationByProjectId(this.protectedProjectId).subscribe(res => {

      this.statusName = res.projectStatus;
      this.solutionStatus = res.solutionStatus;
      this.approvedDeniedBy = res.approvedDeniedBy;
      this.approvedDeniedDate = res.approvedDeniedDate != null ? new Date(res.approvedDeniedDate).toLocaleDateString() : '';

      this.generalInfo = {
        id: res.id,
        organization: res.projectAccount.name,
        primaryContact: res.projectContact != null ? res.projectContact.name : '',
        address: res.site.fullAddress,
        phone: res.projectContact != null ? res.projectContact.phone : '' ,
        propertyType: res.site.buildingType,
        email: res.projectContact != null ? res.projectContact.email : '' ,
        siteEvaluationDate: res.evaluationDate != null ? this.dateFormatter(res.evaluationDate) : '',
        incentiveReservedDate: res.incentiveReservedDate != null ? this.dateFormatter(res.incentiveReservedDate) : '',
        contractorSelectedDate: res.contractorSelectedDate != null ? this.dateFormatter(res.contractorSelectedDate) : '',
        installationCompletedDate: res.installationCompletedDate != null ? this.dateFormatter(res.installationCompletedDate) : '',
        incentivePaidDate: res.paymentDate != null ? this.dateFormatter(res.paymentDate) : '',
        contractorSelected: res.contractor != null ? res.contractor.name : '',
        totalChargersInstalled: res.installedChargers != null && res.installedChargers.length > 0 ? res.installedChargers.length : '',
        totalProjectCost: res.totalProjectCost != null && res.totalProjectCost > 0 ? '$' + parseInt(res.totalProjectCost).toLocaleString('en-US', { minimumFractionDigits: 0 }) : '',
        incentiveAmountPaid: res.totalIncentiveAmount != null && res.totalIncentiveAmount > 0 ? '$' + parseInt(res.totalIncentiveAmount).toLocaleString('en-US', { minimumFractionDigits: 0 }) : ''
      };

      if (res.solutions != null) {
      this.solutionData.push(res.selectedSolution);

      if (this.solutionData != null) {
          this.solutionData.forEach(data => {
              data.totalChargers = data.chargers.reduce((toal, q) => {
                return  toal + q.quantity;
            }, 0);
              data.totalChargersCost = data.chargers.reduce((toalCost, d) => {
                return  toalCost + d.cost;
              }, 0);
          });
        }
      }
      this.projectAttachments = res.projectAttachments;
      console.log(this.projectAttachments);
    });

  }

  dateFormatter(input: string) {
    return formatDate(input, 'MM/dd/yyyy', 'en_US')
  }

  intializeGeneralInfo() {
    this.generalInfo = {
      id: '',
      organization: '',
      primaryContact: '',
      address: '',
      phone: '',
      propertyType: '',
      email: '',
      siteEvaluationDate: '',
      incentiveReservedDate: '',
      contractorSelectedDate: '',
      installationCompletedDate: '',
      incentivePaidDate: '',
      contractorSelected: '',
      totalChargersInstalled: '',
      totalProjectCost: '',
      incentiveAmountPaid: ''
    };
  }

  goBack() {
    this.router.navigate(['/next-steps', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

}
