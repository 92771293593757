
import { Component, OnInit, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { google } from 'google-maps';
import { Router } from '@angular/router';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { EligiblePropertyService } from 'src/app/services/eligible-property/eligible-property.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { Logger } from 'src/app/core/logger.service';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';

// declare module 'google';
const log = new Logger('EligibilityFormComponent');

@Component({
  selector: 'app-eligibility-form',
  templateUrl: './eligibility-form.component.html',
  styleUrls: ['./eligibility-form.component.scss']
})
export class EligibilityFormComponent implements OnInit, AfterViewInit {
  eligibilityContent = AppConfigService.settings.landingPage.eligibility;
  checkEligibility = AppConfigService.settings.checkEligibilityPage.checkEligibility;
  @Input() adressType = 'address';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', { static: true }) addresstext: any;

  eligibilityForm: FormGroup;
  addressForm = {
    streetNumber: '',
    route: '',
    city: '',
    state: '',
    postalCode: '',
    longRoute: '',
    streetNameFc: ''
  };

  timeout:any;
  setStreetAddress = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private workflowService: WorkflowService,
    private eligiblePropertyService: EligiblePropertyService,
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private appInsightsService: AppInsightsService
  ) {
  }

  ngOnInit() {
    this.eligibilityForm = this.fb.group({
      streetAddress: [''],
      unit: [''],
      city: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]],
      state: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]],
      postalCode: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]],
      streetNumber: [''],
      streetNameFc: ['', [Validators.required, Validators.pattern(/.*[^ ].*/)]]
    });
  }



  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        // componentRestrictions: { country: '*' },
        types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      // tslint:disable-next-line: no-unused-expression
      place.address_components ? log.debug('GOOGLE API VALUES', place.address_components) : '';
      this.invokeEvent(place);
      this.populateAddress(place);
    });

    google.maps.event.addListener(autocomplete, 'keyup', (v) => {
      console.log("key--up---",v);
    });
  }

  capitalizeFirstLetter(string) {

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  replaceStreetWords(address) {
    let streetDirections ={
      "north": "N",
      "northeast": "NE",
      "east": "E",
      "southeast": "SE",
      "south": "S",
      "southwest": "SW",
      "west": "W",
      "northwest": "NW"
    };

    var streetSuffixes = {
      "avenue": "Ave",
      "building": "BLDG",
      "boulevard": "BLVD",
      "center": "CTR",
      "circle": "CIR",
      "crossing": "XING",
      "court": "CT",
      "cove": "Cv",
      "drive": "Dr",
      "freeway": "FWY",
      "heights": "HTS",
      "highway": "Hwy",
      "lane": "LN",
      "place": "PL",
      "plaza": "PLZ",
      "parkway": "PKWY",
      "point": "PT",
      "road": "Rd",
      "square": "Sq",
      "street": "St",
      "terrace": "TER",
      "landing": "LNDG",
      "trail": "TRL",
      "island": "ISL",
      "park": "PK",
      "way": "Wy",
      "turnpike": "TPKE",
      "bypass": "BYP",
      "gardens": "GDNS",
      "pike": "PKE",
      "view": "VW",
      "whrf": "WRF",
      "warehouse": "WHARF"
    };
    
    var otherReplacements = {
        'cañada': 'Canada'
    };
  
    var splitAddress= address.split(" ");
    for(var i=0;i<splitAddress.length;i++){
        if(streetDirections[splitAddress[i].toLowerCase()]){
          splitAddress[i]=streetDirections[splitAddress[i].toLowerCase()]
        }
  
        if(streetSuffixes[splitAddress[i].toLowerCase()]){
          splitAddress[i]=streetSuffixes[splitAddress[i].toLowerCase()]
        }
  
        if(otherReplacements[splitAddress[i].toLowerCase()]){
          splitAddress[i]=otherReplacements[splitAddress[i].toLowerCase()]
        }
  
        // console.log("otherReplacemens------",otherReplacements,)
    }
  
      return splitAddress.join(" ");
      
  }

  clearInput(){
    this.addresstext.nativeElement.value = '';
    this.eligibilityForm.reset();
    this.clearField();
  }
  
  resetIfClear(e){
  if(this.addresstext.nativeElement.value.trim()==''){
    this.timeout = setTimeout(()=>{
      this.eligibilityForm.reset();
     this.clearField();
    },3000);
    console.log("Cleared",this.addresstext.nativeElement.value);
  }
  else{
    clearTimeout(this.timeout);
  }
  }

  clearField(){
    this.addressForm = {
      streetNumber: '',
      route: '',
      city: '',
      state: '',
      postalCode: '',
      longRoute: '',
      streetNameFc: ''
    };
    }

  invokeEvent(place) {
    this.setAddress.emit(place);
  }

  onSubmit(): void {
    if (this.eligibilityForm.valid) {
      this.workflowService.setEligibility(true);
      //console.log('streetNameFc', this.eligibilityForm.controls.streetNameFc.value);
      const formData = {
        ...this.eligibilityForm.value,
        StreetName: this.addressForm.route,
        StreetNameLong: this.addressForm.longRoute,
        streetAddress: this.eligibilityForm.controls.streetNumber.value + ' ' + this.eligibilityForm.controls.streetNameFc.value,
        StreetNameManual: this.eligibilityForm.controls.streetNameFc.value
      };

      log.debug('FINAL VALUES', formData);

      this.eligiblePropertyService.verifyEligibilityAddress(formData).subscribe((response) => {
        if (response && response.success) {
          if (response.dataValues && response.dataValues.length) {
            response.dataValues.forEach((element) => {
              if (element.name === 'Address1') {
                formData.streetAddress = element.value;
              } else if (element.name === 'City') {
                formData.city = element.value;
              } else if (element.name === 'State') {
                formData.state = element.value;
              } else if (element.name === 'ZipCode') {
                formData.postalCode = element.value;
              } else if (element.name === 'Reference1') {
                this.workflowService.setServiceNumber({
                  serviceNumber: element.value
                });
              }
            });
          } else {
            this.workflowService.setServiceNumber({
              serviceNumber: ''
            });
          }
          this.workflowService.setEligibleFormData(formData);
          this.workflowService.setEligibility(true);
          this.router.navigate(['workflow/contact-details']);
        } else {
          this.workflowService.setEligibility(false);
          this.router.navigate(['workflow/eligibility-check']);
        }
      }, (error) => {
        this.handleErrors(error);
      });
      // this.loaderService.setSpinner(true);
      // setTimeout(() => {
      //   this.loaderService.setSpinner(false);
      //   this.router.navigate(['workflow/contact-details']);
      // }, 1000);

    } else {
      this.workflowService.validateAllFormFields(this.eligibilityForm);
      this.workflowService.setEligibility(false);
      // this.router.navigate(['workflow/eligibility-check']);
    }

  }
  populateAddress(place) {
  
    var m:any;
    this.setStreetAddress = false;
    this.clearField();

    const addressComponents: any = place.address_components;
    addressComponents.forEach((addressComponent: any, index: number) => {
      const types = addressComponent.types;
      types.forEach((type: string, i) => {
        if (type === 'locality') {
          this.addressForm.city = addressComponent.long_name;
        }
        if (type === 'administrative_area_level_1') {
          this.addressForm.state = addressComponent.short_name;
        }
        if (type === 'street_number') {
          this.addressForm.streetNumber = addressComponent.long_name;
        }
        if (type === 'route') {
          this.addressForm.route = addressComponent.short_name;
          this.addressForm.longRoute = addressComponent.long_name;
          this.addressForm.streetNameFc = this.replaceStreetWords(addressComponent.long_name);
          this.addresstext.nativeElement.value =  this.addresstext.nativeElement.value.replaceAll(addressComponent.long_name,this.addressForm.streetNameFc);
        }
        if (type === 'postal_code') {
          this.addressForm.postalCode = addressComponent.short_name;
        }

        // log.debug('addressForm', this.addressForm);
      });
    });
    this.eligibilityForm.patchValue({
      city: this.addressForm.city,
      state: this.addressForm.state,
      postalCode: this.addressForm.postalCode,
      streetNumber: this.addressForm.streetNumber,
      streetNameFc: this.addressForm.streetNameFc
    });
  }
  
  handleErrors(error) {
    if (error && error.error) {
      if (error.error.errorCode === 'e001') {
        this.toasterService.showError(error.error.message);
      } else {
        this.appInsightsService.logException(error);
        this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
      }
    } else {
      this.appInsightsService.logException(error);
      this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
    }
  }

}
