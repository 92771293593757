export const environment = {
  production: false,
  apiUrl: 'https://ev-portal-api.clearesult-dev.com/api',
  environmentName: 'DEV',
  appInsights: {
    name: 'c90068dain002',
    instrumentationKey: 'a5b3d41a-06fe-4872-ad75-19d507a49207'
  },
  tenants: new Map<string, string>([
    ['localhost', 'tep'],
    ['tepev-dev.clearesult.com', 'tep'],
    ['pceev-dev.clearesult.com', 'pce'],
    ['svceev-dev.clearesult.com', 'svce'],
  ]),
  adb2c: {
    authority: 'https://clrb2cdev.b2clogin.com/clrb2cdev.onmicrosoft.com/',
    AdSignInUrl: 'https://clrb2cdev.b2clogin.com/clrb2cdev.onmicrosoft.com/B2C_1_Signup_Signin_Dev',
    AdSignInSignUpUrl: 'https://clrb2cdev.b2clogin.com/clrb2cdev.onmicrosoft.com/B2C_1_Signup_Signin_Dev',
    AdForgotUrl: 'https://clrb2cdev.b2clogin.com/clrb2cdev.onmicrosoft.com/B2C_1_PasswordReset_Dev',
    clientID: '48e267e7-636f-43ac-9489-e94dcf951109',
    tenant: 'clrb2cdev.onmicrosoft.com',
    b2cScopes: ['https://clrb2cdev.onmicrosoft.com/api/api.read', 'https://clrb2cdev.onmicrosoft.com/api/api.write'],
    EnablePII : true
  }
};
