import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AssistYouComponent } from '../assist-you/assist-you.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';  
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';


@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
  styles: [`
  .ui-steps .ui-steps-item {
    width: 20%;
  }

  .ui-steps.steps-custom {
      margin-bottom: 30px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
      padding: 0 1em;
      overflow: visible;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
      background-color: #0081c2;
      color: #FFFFFF;
      display: inline-block;
      width: 36px;
      border-radius: 50%;
      margin-top: -14px;
      margin-bottom: 10px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
      color: #555555;
  }
`],
encapsulation: ViewEncapsulation.None
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  invalidContractorSelected: boolean = false;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              public dialogService: DialogService,
              public contractorService : ContractorService,
              private toasterService: ToasterService,
              public loaderService: LoaderService,
              private dashboardService: DashboardService) { }

  items:any;
  activeIndex: number;
  projectId: string;
  protectedProjectId: string;
  id: string
  routeSubscription: Subscription;
  displayText: any;
  ref: DynamicDialogRef;
  addContractor: boolean = false;
  hideContractor: boolean = true;
  formData: FormData = new FormData();
  finalAttachments: any[] = [];


  contractorForm = this.fb.group({
    solutionChangedComments : [''],
    contractor: [''],
    projectCost: [''],
    scopeChange: [''],
   
 });

 newContractorAddForm = this.fb.group({
    companyName: [''],
    firstName : [''],
    lastName: [''],
    address : [''],
    city : [''],
    state : [''],
    zip : [''],
    email : [''],
    license : [''],
    phone : [''],
    ext : [''],
 })

 payload = {
  "projectStage":"Installation",
  "projectStatus":"Contractor Selected",
  "initialSolutionChanged":true,
  "solutionChangedComments":"",
  "estimatedProjectCosts": 1000,
  "isContractorAdded":true,
  "isContractorVerified":false,
  "primaryContractor":"",
  "contractor":{
     "name":"",
     "active": "Yes",
     "primaryContactName":"",
     "primaryContactPhone":"",
     "primaryContactEmail":"",
     "licenseNumber":"",
     "billingStreet":"",
     "billingCity":"",
     "billingState":"",
     "billingPostalCode":""
  },
  "projectAttachments":{

  }
}

   contractors: any[] = [];
   contractorSelectedOption: any; 
   projectAttachments: any[] = [];
   solutionData: any[] = [];  

   contractorSubscription: Subscription;
   uploadSubscription: Subscription;
   removeSubscription: Subscription;
   reviewApplicationSubscription: Subscription;

 

  ngOnInit() {
    this.scopeChangeValueChanged();
    this.displayText = AppConfigService.settings.contractorDetailsPage;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
      this.id = atob(params.id);
    });
    this.intializeCustomSteps();
    this.activeIndex = 3;
    this.addContractor = false;

    this.contractorSubscription =  this.contractorService.getContractors().subscribe(response => {
        this.contractors = response;
    });

    this.reviewApplicationSubscription = this.dashboardService.getReviewApplicationByProjectId(this.protectedProjectId).subscribe(res => {
      if (res) {
        this.solutionData.push(res.selectedSolution);

        if (this.solutionData != null) {
          this.solutionData.forEach(data => {
              data.totalChargers = data ? data.chargers.reduce((toal, q) => {
                return  toal + q.quantity;
            }, 0) : '';
              data.totalChargersCost = data ? data.chargers.reduce((toalCost, d) => {
                return  toalCost + d.cost;
              }, 0) : '';
          });
        }
      }
    });

  }

  scopeChangeValueChanged() {
    const solutionChanged = this.contractorForm.get('solutionChangedComments');
    this.contractorForm.get('scopeChange').valueChanges.subscribe(
        (mode: string) => {
            if (JSON.parse(mode)) {
              solutionChanged.setValidators([Validators.required]);
            }
            else {
              solutionChanged.clearValidators();
            }
           
            solutionChanged.updateValueAndValidity();
        });
  }

  onAddContractor() {
   this.addContractor = true;
   this.hideContractor = false;
  }

  onHideContractor() {
    this.addContractor = false;
    this.hideContractor = true;
  }

  onSelect(event: TypeaheadMatch): void {  
        this.contractorSelectedOption = event.item;  
  }
  
  onContractorBlur(event) {
    if (this.contractorForm.controls.contractor.value) {
      const isContractorAvailable = this.contractors.filter(x => x.name === this.contractorForm.controls.contractor.value)[0];
      if (!isContractorAvailable) {
        this.invalidContractorSelected = true;
        this.contractorForm.controls.contractor.setErrors({incorrect: true});

      }
    } else {}
  }

  intializeCustomSteps() {

    this.items = [
      {
        label: 'Onsite Evaluation',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        label: 'Scope Selection',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        label: 'Request Bids',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-list-ul'
      },
      {
        label: 'Contract Details',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-shield'
      },
      {
        icon: 'fa fa-check',
        label: this.displayText.customStep5,
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  get fcontrols() {
    return this.contractorForm.controls;
  }

  get cfcontrols() {
    return this.newContractorAddForm.controls;
  }

  formatGsm(inputValue: String, data: string) {
      if(data) {
        const value = inputValue.replace(/[^0-9]/g, ''); 
        let format = '(***) ***-****'; 
      
        if(value == "") {
          format = "";
        }
        else {
          for (let i = 0; i < value.length; i++) {
            format = format.replace('*', value.charAt(i));
          }
        
          if (format.indexOf('*') >= 0) {
            format = format.substring(0, format.indexOf('*'));
          }
      }
      this.newContractorAddForm.controls['phone'].setValue(format);
  }
}

  show(){
    this.ref = this.dialogService.open(AssistYouComponent, {
      data: {
        projectId : this.projectId,
        protectedProjectId : this.protectedProjectId
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  submit(){
      this.finalAttachments.forEach(x => {
            this.formData.append("file[]",  x,  x.name);
      })
     
     this.uploadSubscription = this.contractorService.upload(this.formData, this.id,  "Contract").subscribe(response =>{
      this.payload.projectAttachments = response.map((d, i) => {
                  return {
                      attachmentType : "Contract",
                      attachmentName: this.finalAttachments[i].name,
                      fileKey: d.guid,
                      rebate: this.id,
                      status : "Uploaded",
                      attachmentSource : "EV Portal"
                  }
          });
          this.payload.initialSolutionChanged = JSON.parse(this.contractorForm.controls.scopeChange.value);
          this.payload.solutionChangedComments = this.contractorForm.controls.solutionChangedComments.value;
          this.payload.estimatedProjectCosts = parseInt(this.contractorForm.controls.projectCost.value);
          this.payload.isContractorAdded = this.addContractor;
          this.payload.primaryContractor = this.contractorSelectedOption ? this.contractorSelectedOption.id : "";
          if (!this.addContractor) {
              this.payload.contractor = null;
          }
          else{
            let extSeperator = this.newContractorAddForm.controls.ext.value ? 'x' : '';
            this.payload.contractor.name = this.newContractorAddForm.controls.companyName.value;
            this.payload.contractor.primaryContactName = this.newContractorAddForm.controls.firstName.value + ' ' + this.newContractorAddForm.controls.lastName.value;
            this.payload.contractor.primaryContactPhone = this.newContractorAddForm.controls.phone.value + extSeperator + this.newContractorAddForm.controls.ext.value;
            this.payload.contractor.primaryContactEmail = this.newContractorAddForm.controls.email.value;
            this.payload.contractor.licenseNumber = this.newContractorAddForm.controls.license.value;
            this.payload.contractor.billingStreet = this.newContractorAddForm.controls.address.value;
            this.payload.contractor.billingCity = this.newContractorAddForm.controls.city.value;
            this.payload.contractor.billingState = this.newContractorAddForm.controls.state.value;
            this.payload.contractor.billingPostalCode = this.newContractorAddForm.controls.zip.value;
          }
          this.saveContractor();
      });
  }

  saveContractor() {
    this.contractorSubscription = this.contractorService.saveContractorDetails(this.id, this.payload).subscribe(response => {
      if(response.success){
        this.router.navigate(['updated-contractor', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
      }
  });
}

  onSelectFile(data: { files: File }) {
      for(var i =  0; i <  data.files["length"]; i++)  {  
        this.finalAttachments.push(data.files[i]);
      } 
  }

  public removeFile(data: File) {
    this.loaderService.setSpinner(true);
    let selectedAttachment = this.finalAttachments.find(x => x.name == data["file"].name);
    if(selectedAttachment) {
      this.finalAttachments = this.finalAttachments.filter(x => x.name != data["file"].name);
      this.loaderService.setSpinner(false); 
    }
  }

  ngOnDestroy() {
    if(this.contractorSubscription) {
      this.contractorSubscription.unsubscribe();
    }
    if(this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
    if(this.removeSubscription) {
      this.removeSubscription.unsubscribe();
    }
  }
}
