import { CLIENT_CONFIG } from './../../../constants/client-configuration';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from 'src/app/services/app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private toasterService: ToasterService,
              private appInsightsService: AppInsightsService) { }

  handleError(error: any) {
    this.appInsightsService.logException(error);
    if (error instanceof HttpErrorResponse) {
      console.log(error.status);
      this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
    } else {
      console.error('An error occurred', error);
      this.toasterService.showError(CLIENT_CONFIG.errors.clientSideError);
    }
  }
}
