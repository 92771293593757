import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService
  ) { }
  
  displayNames: any;
  displayText: any;
  displayNameKeys: any;
  routeSubscription: Subscription;
  reviewApplicationSubscription: Subscription;

  @Input() generalInfo : any;
  
  ngOnInit() {
    this.displayNames= AppConfigService.settings.generalInformationPage.generalInfo;
    this.displayText = AppConfigService.settings.generalInformationPage.textLanguage;
    this.displayNameKeys = Object.keys(this.displayNames);

    

  }

}
