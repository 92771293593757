import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from './must-match.validator';
import { ClientRegistrationService } from 'src/app/services/client-registration/client-registration.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MsalService} from '@azure/msal-angular';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { MessageService } from 'primeng/api';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ConfirmRegistrationComponent } from '../customerdashboard/confirm-registration/confirm-registration.component';

@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.scss']
})
export class ClientRegistrationComponent implements OnInit {
  registrationType: string;
  emailId: string;
  clientRegistrationSubscription: Subscription;
  routeSubscription: Subscription;
  displayText: any;
  ref: DynamicDialogRef;


  constructor(private fb: FormBuilder,
              private clientRegistrationService: ClientRegistrationService,
              private router: Router,
              private authService: MsalService,
              private tenantService: TenantService,
              private toasterService: ToasterService,
              public loaderService: LoaderService,
              private appInsightsService: AppInsightsService,
              private activatedRoute: ActivatedRoute,
              private messageService: MessageService,
              public dialogService: DialogService
    ) { }
  submitted = false;
  patternString: any = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/
  @Input() password = '';
  @Input() confirmedPassword = '';
  registrationForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    pswd: ['', [Validators.required, Validators.minLength(14),
      Validators.pattern(this.patternString)]], // nosonar
    cnfmpswd: ['', [Validators.required]]
  },
  {
    validator: MustMatch('pswd', 'cnfmpswd')
  }
  );

  ngOnInit() {
    this.displayText = AppConfigService.settings.generalInformationPage.textLanguage;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      if (params.email) {
        this.emailId = params.email;
        this.registrationForm.patchValue({
          email : this.emailId
        });
        this.registrationForm.controls['email'].disable();
      }
    });
    this.registrationType = (this.router.url === '/client-registration') ? 'Client' : 'Customer';
  }

  get fcontrols() {
    return this.registrationForm.controls;
  }

  show() {
    this.ref = this.dialogService.open(ConfirmRegistrationComponent, {
      data: {
        email: this.registrationForm.controls.email.value,
        password: this.registrationForm.controls.pswd.value,
        registrationType : this.registrationType
      },
      header: 'Registration Confirmation',
      showHeader: false,
      dismissableMask: true,
      width: '30%'
    });
  }

  onSubmit() {
    if (this.registrationForm.invalid) {
      return;
    }

    const data = {
      email: this.registrationForm.controls.email.value,
      password: this.registrationForm.controls.pswd.value,
      registrationType : this.registrationType
    };

    this.clientRegistrationSubscription = this.clientRegistrationService.Registration(data).subscribe(result => {
    if (result.isSuccess) {
      this.show();
      // this.authService.authority = environment.adb2c.AdSignInUrl;
      // this.loaderService.setSpinner(true);
      // const extraQueryParameters = this.tenantService.getQueryStringParms();
      // this.authService.loginRedirect(null, extraQueryParameters);
      } else {
        if (result.message.indexOf('userPrincipalName already exists') >= 0) {
            const errorMsg = 'Email address has already been registered. If you wish to continue, click Sign In.'
            this.messageService.add({ severity: 'error', summary: 'Error Message', detail: errorMsg, life: 15000 });
          } else {
          this.appInsightsService.logException(result);
          this.toasterService.showError(result.message);
        }
      }
    }
   );
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.clientRegistrationSubscription) {
      this.clientRegistrationSubscription.unsubscribe();
    }
  }

}
