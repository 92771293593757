import { LoaderService } from './../../shared/services/loader/loader.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Logger } from 'src/app/core/logger.service';
const log = new Logger('LoginComponent');
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  submitted = false;
  loginForm: FormGroup;
  error = '';

  token: string;

  private broadcaseSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loaderService: LoaderService,
   
  ) {
    
  }

  login() {
    log.debug('login works....', this.f);
    log.debug('this.email', this.f.email);
    log.debug('this.password', this.f.password);
    this.loaderService.setSpinner(true);
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.error = '';
      this.loaderService.setSpinner(false);
      return;
    }
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
}
