import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss']
})
export class JumbotronComponent implements OnInit {
  jumbotron = AppConfigService.settings.landingPage.jumbotron;
  constructor() { }

  ngOnInit() {
    document.getElementById('carImg').style.background = this.jumbotron.backgroundImg1;
    document.getElementById('homeBackgroundImg').style.background = this.jumbotron.backgroundImg2;
  }

}
