import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-incentive-success',
  templateUrl: './incentive-success.component.html',
  styleUrls: ['./incentive-success.component.scss']
})
export class IncentiveSuccessComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  textLanguage: any;
  routeSubscription: Subscription;
  id: string;
  projectId: string;
  protectedProjectId: string;

  ngOnInit() {
    this.textLanguage = AppConfigService.settings.incentiveSuccess;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.id = atob(params.id);
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
  });
}

  goBack() {
    this.router.navigate(['customer-dashboard']);
  }

  goToProjectSummary() {
    this.router.navigate(['/next-steps', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

}
