import { Injectable } from '@angular/core';
import { Theme } from './themes/theme';
import { tepTheme } from './themes/tep.theme';
import { pceTheme } from './themes/pce.theme';
import { svceTheme } from './themes/svce.theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private activeTheme: Theme;
  private availableThemes: Theme[] = [tepTheme, pceTheme, svceTheme];

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  setTheme(host: string): void {
    const themes = this.getAvailableThemes();
    this.activeTheme = themes.find(th => th.name === host);
    Object.keys(this.activeTheme.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.activeTheme.properties[property]
      );
    });
  }

  setFavicon(host: string): void {
    const themes = this.getAvailableThemes();
    this.activeTheme = themes.find(th => th.name === host);
           const linkElement = document.createElement( 'link' );
    linkElement.setAttribute( 'rel', 'icon' );
    linkElement.setAttribute( 'type', 'image/x-icon' );
    linkElement.setAttribute( 'href', this.activeTheme.favicon );
    document.head.appendChild( linkElement );
  }
}
