import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { ScrollTopService } from './shared/services/scroll-top/scroll-top.service';
import { TenantService } from './services/tenant/tenant.service';
import { ThemeService } from './services/theme/theme.service';
import { Title, Meta } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { GaService } from './shared/services/ga/ga.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  config = null;
  metaData: any;

  constructor(
    private scrollTopService: ScrollTopService,
    private tenantService: TenantService,
    private themeService: ThemeService,
    private titleService: Title,
    private metaService: Meta,
    private msalService: MsalService,
    private router: Router,
    private gaService: GaService,
    private renderer: Renderer2, 
    @Inject(DOCUMENT) 
    private document: Document
  ) {
    (this.msalService as any)['_redirectUri'] = window.location.origin;
    (this.msalService as any)['_postLogoutredirectUri'] = window.location.origin;
  }

  ngOnInit() {
    this.metaData = AppConfigService.settings.headMetadata;
    this.config = CLIENT_CONFIG;
    this.scrollTopService.setScrollTop();
    this.googletagmanager();
    ///this.loadScript2();
    this.applyTheme();
    this.loadGA();
    this.applyMetadata();
    this.gaService.initiateGMapTracking();
    this.cookie_load();    
    window.addEventListener('loadCustomEvent', () => this.cookie_load());

  }

  
  loadGA() {
    //const host = window.location.hostname;
    const host = this.tenantService.getHostname();
    let clientCode = ' ';
    if (host.indexOf('tep'||'localhost') >= 0) {
            clientCode = 'GTM-MMJRLFM';
            //Note: console.log('tep ' + clientCode)
        } else if (host.indexOf('pce') >= 0) {
            clientCode = 'GTM-MRHLGRMH';
            //Note: console.log('tep' + clientCode)
        } else if (host.indexOf('svce') >= 0) {
            clientCode = 'GTM-N4JJMT4N';
            //Note: console.log('tep' + clientCode)
        }
          //Note: console.log('clientCode: ' + clientCode)
    const gaScript = document.createElement('script');
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${clientCode}`;
    const loadGAScript = document.createElement('script');
    loadGAScript.innerText = `
    window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());
        gtag('config', '${clientCode}');`;
    document.body.appendChild(gaScript);
    document.body.appendChild(loadGAScript);
  }

  
  googletagmanager() {
    //const host = window.location.hostname;
    const host = this.tenantService.getHostname();
    let clientCode = ' ';
    //Note: console.log('host', host);
    if (host.indexOf('tep'||'localhost') >= 0) {
      clientCode = 'GTM-MMJRLFM';
    } else if (host.indexOf('pce') >= 0) {
      clientCode = 'GTM-MRHLGRMH';
    } else if (host.indexOf('svce') >= 0) {
      clientCode = 'GTM-N4JJMT4N';
    }
    const node = document.createElement('noscript');
    const createIframe = document.createElement('iframe');
    createIframe.src = 'https://www.googletagmanager.com/ns.html?id=' + clientCode;
    createIframe.height = '0';
    createIframe.width = '0';
    createIframe.title = 'Google analytics';
    createIframe.setAttribute('display', 'none');
    createIframe.setAttribute('visibility', 'hidden');
    node.appendChild(createIframe);
    document.getElementsByTagName('body')[0].appendChild(node);
  }

  loadScript2(src: string, dataDomainScript: string = '') {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (dataDomainScript) {
      script.setAttribute('data-domain-script', dataDomainScript);
    }
    this.renderer.appendChild(this.document.head, script);
  }
  
  cookie_load() {
    const host = window.location.hostname;
    console.log('host: ', host);
    let vhost = ' ';

    if (host.indexOf('svce') >= 0) {
      vhost = 'svce';
      // TEST svce
      // const script1 = document.createElement('script');
      // script1.src = 'https://cdn.cookielaw.org/consent/6dc45db6-bff7-48df-9d12-4b282513d8d2-test/OtAutoBlock.js';
      // document.head.appendChild(script1);
      // const script2 = document.createElement('script');
      // script2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      // script2.setAttribute('data-domain-script', '6dc45db6-bff7-48df-9d12-4b282513d8d2-test');
      // document.head.appendChild(script2);
     // TEST svce
      // PROD svce
      const script1 = document.createElement('script');
      script1.src = 'https://cdn.cookielaw.org/consent/6dc45db6-bff7-48df-9d12-4b282513d8d2/OtAutoBlock.js';  
      document.head.appendChild(script1);  
      const script2 = document.createElement('script');
      script2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script2.setAttribute('data-domain-script', '6dc45db6-bff7-48df-9d12-4b282513d8d2');
      document.head.appendChild(script2);
      // PROD svce

    } else if (host.indexOf('pce') >= 0) {
      vhost = 'pce';
      // TEST pce
      // const script3 = document.createElement('script');
      // script3.src = 'https://cdn.cookielaw.org/consent/5357bfe4-014c-4e59-9df2-0d94f4b5101a-test/OtAutoBlock.js';
      // document.head.appendChild(script3);
      // const script4 = document.createElement('script');
      // script4.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      // script4.setAttribute('data-domain-script', '5357bfe4-014c-4e59-9df2-0d94f4b5101a-test');
      // document.head.appendChild(script4);
      // TEST pce
      // PROD pce
      const script3 = document.createElement('script');
      script3.src = 'https://cdn.cookielaw.org/consent/5357bfe4-014c-4e59-9df2-0d94f4b5101a/OtAutoBlock.js';
      document.head.appendChild(script3);
      const script4 = document.createElement('script');
      script4.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script4.setAttribute('data-domain-script', '5357bfe4-014c-4e59-9df2-0d94f4b5101a');
      document.head.appendChild(script4);
      // PROD pce
       
    } else if (host.indexOf('tep') >= 0) {
      vhost = 'tep';
    } else if (host.indexOf('localhost') >= 0) {
      vhost = 'localhost-pce';
      const script1 = document.createElement('script');
      script1.src = 'https://cdn.cookielaw.org/consent/6dc45db6-bff7-48df-9d12-4b282513d8d2-test/OtAutoBlock.js';
      
      document.head.appendChild(script1);
  
      const script2 = document.createElement('script');
      script2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script2.setAttribute('data-domain-script', '6dc45db6-bff7-48df-9d12-4b282513d8d2-test');
      document.head.appendChild(script2);
    }
    var scriptX = document.createElement('script');
    scriptX.type = 'text/javascript';
    scriptX.innerHTML = 'function OptanonWrapper() { }';    
    document.head.appendChild(scriptX);
    
    console.log ("Header:" + vhost);
  }

  applyTheme() {
    const tenant = this.tenantService.getTenantName();
 // Note:   console.log('tenant', tenant);
    this.themeService.setTheme(tenant);
    this.themeService.setFavicon(tenant);
  }

  applyMetadata() {
    this.titleService.setTitle(this.metaData.title);
    this.metaService.addTag({name: 'title', content: this.metaData.title});
    this.metaService.addTag({name: 'description', content: this.metaData.description});
  }
}
