import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';


@Component({
  selector: 'app-assist-you',
  templateUrl: './assist-you.component.html',
  styleUrls: ['./assist-you.component.scss']
})
export class AssistYouComponent implements OnInit {

 contactForm = this.fb.group({
    description : ['', Validators.required]
 });
 projectId: string;
 protectedProjectId: string;
 payload: any;
 contactSubscription: Subscription;
 id: string;
 routeSubscription: Subscription;
  popupContent: any;
  constructor( public ref: DynamicDialogRef,
               private dashboardService: DashboardService, 
               public config: DynamicDialogConfig,
               private fb: FormBuilder,
               private router: Router,
               private activatedRoute: ActivatedRoute
              ) { }

  ngOnInit() {
    this.projectId = this.config.data.projectId;
    this.protectedProjectId = this.config.data.protectedProjectId;
    this.id = this.config.data.id;
    this.popupContent = AppConfigService.settings.notEligiblePage.popupSection;
  }
  submitDescription() {
    this.payload = {
      'description': this.contactForm.controls.description.value
    };
    this.contactSubscription = this.dashboardService.saveContactDetails(this.protectedProjectId, this.payload).subscribe(response => {
      if (response) {
        this.close();
        this.router.navigate(['incentive-success', btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
      }
    });
  }

  close() {
    if (this.ref) {
      this.ref.close();
    }
  }

}
