import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ClientRegistrationService {

  constructor(private httpService: HttpService) { }
  Registration(contactInfo) {
    return this.httpService.post('/User/UserRegistration', contactInfo);
  }

  GetContactByEmail(email: string) {
    return this.httpService.get('/contact/' + email);
  }
}
