import { Component, OnInit, OnDestroy } from '@angular/core';
import { GeneralInfo } from 'src/app/shared/models/generalinfo';
import { ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { saveAs } from 'file-saver/FileSaver';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { MsalService } from '@azure/msal-angular';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-review-solution',
  templateUrl: './review-solution.component.html',
  styleUrls: ['./review-solution.component.scss'],
  styles: [`
        .ui-steps .ui-steps-item {
          width: 20%;
          margin-left: 20%;
        }

        .ui-steps.steps-custom {
            margin-bottom: 30px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
            padding: 0 1em;
            overflow: visible;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
            background-color: #0081c2;
            color: #FFFFFF;
            display: inline-block;
            width: 36px;
            border-radius: 50%;
            margin-top: -14px;
            margin-bottom: 10px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
            color: #555555;
        }
    `],
  encapsulation: ViewEncapsulation.None
})
export class ReviewSolutionComponent implements OnInit, OnDestroy {

  generalInfo: GeneralInfo;
  items:any;
  solutionData: any[] = [];
  displayNames: any;
  displayText: any;
  displayNameKeys: any;
  activeIndex = 0;
  projectId: string;
  protectedProjectId: string;
  statusName: string;
  approvedDeniedBy: string;
  approvedDeniedDate: string;
  solutionStatus:string;
  solutionStatusApproved:string="Approved";
  solutionStatusDenied:string="Denied";
  reviewForm = this.fb.group({
    status: [''],
    revisions: ['', Validators.maxLength(5)]
  });

  routeSubscription: Subscription;
  reviewApplicationSubscription: Subscription;
  submitSubscription: Subscription;
  subscription: Subscription;
  
  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute,
              private dashboardService: DashboardService,
              private rotuer: Router,
              private toasterService: ToasterService,
              private authService: MsalService
              ) { }
  
  ngOnInit() {
    this.displayNames= AppConfigService.settings.generalInformationPage.generalInfo;
    this.displayText = AppConfigService.settings.generalInformationPage.textLanguage;
    this.displayNameKeys = Object.keys(this.displayNames);
    this.intializeGeneralInfo();
    
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
    });

    this.intializeCustomSteps();
    this.reviewApplicationSubscription = this.dashboardService.getReviewApplicationByProjectId(this.protectedProjectId).subscribe(res => {

      this.statusName = res.projectStatus;
      this.solutionStatus=res.solutionStatus;
      this.approvedDeniedBy = res.approvedDeniedBy;
      this.approvedDeniedDate = res.approvedDeniedDate != null ? new Date(res.approvedDeniedDate).toLocaleDateString() : "";

      if(this.statusName == "Solution Review" && this.approvedDeniedBy != null) {
          this.reviewForm.controls['revisions'].setValue(res.clientComments);
          this.reviewForm.controls['revisions'].disable();
      }

      this.generalInfo = {
        id: res.id,
        organization: res.projectAccount.name,
        primaryContact: res.projectContact != null ? res.projectContact.name : '',
        address: res.site.fullAddress,
        phone: res.projectContact != null ? res.projectContact.phone : '' ,
        propertyType: res.site.buildingType,
        email: res.projectContact != null ? res.projectContact.email : '' ,
        siteEvaluationDate: res.evaluationDate != null ? this.dateFormatter(res.evaluationDate) : '',
        incentiveReservedDate: res.incentiveReservedDate != null ? this.dateFormatter(res.incentiveReservedDate) : '',
        contractorSelectedDate: res.contractorSelectedDate != null ? this.dateFormatter(res.contractorSelectedDate) : '',
        installationCompletedDate: res.installationCompletedDate != null ? this.dateFormatter(res.installationCompletedDate) : '',
        incentivePaidDate: res.paymentDate != null ? this.dateFormatter(res.paymentDate) : '',
        contractorSelected: res.contractor != null ? res.contractor.name : '',
        totalChargersInstalled: res.installedChargers != null && res.installedChargers.length > 0 ? res.installedChargers.length : '',
        totalProjectCost: res.totalProjectCost != null && res.totalProjectCost > 0 ? '$' + parseInt(res.totalProjectCost).toLocaleString('en-US', { minimumFractionDigits: 0 }) : '',
        incentiveAmountPaid: res.totalIncentiveAmount != null && res.totalIncentiveAmount > 0 ? '$' + parseInt(res.totalIncentiveAmount).toLocaleString('en-US', { minimumFractionDigits: 0 }) : ''
      };

      if(res.solutions != null) {
      this.solutionData = res.solutions.records;

      if(this.solutionData != null) {
          this.solutionData.forEach(data => {
              data.totalChargers = data.chargers.reduce((toal, q) => {
                return  toal + q.quantity;
            }, 0);
              data.totalChargersCost = data.chargers.reduce((toalCost, d) => {
                return  toalCost + d.cost;
              }, 0)
          })
        }
      }

      if((this.statusName != 'Solution Review' && this.statusName != 'Client Approving')
        && this.approvedDeniedBy != null){
        this.activeIndex = 1;
      }
      
    });
  }

  intializeCustomSteps() {

    this.items = [
      {
        label: 'Review Scopes',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        icon: 'fa fa-check',
        label: 'Solutions Approved',
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  intializeGeneralInfo() {
    this.generalInfo = {
      id: '',
      organization: '',
      primaryContact: '',
      address: '',
      phone: '',
      propertyType: '',
      email: '',
      siteEvaluationDate: '',
      incentiveReservedDate: '',
      contractorSelectedDate: '',
      installationCompletedDate: '',
      incentivePaidDate: '',
      contractorSelected: '',
      totalChargersInstalled: '',
      totalProjectCost: '',
      incentiveAmountPaid: ''
    };
  }

  dateFormatter(input: string) {
      return formatDate(input, 'MM/dd/yyyy', 'en_US')
  }

  downloadDocument(solution: any) {
    if (solution.attachment !== undefined) {
      this.dashboardService.getDownloadDocument(solution.attachment)
      .subscribe(
              success => {
                saveAs(success, solution.attachment.attachmentName);
              },
              (error) => {
                  this.toasterService.showError('File not found');
              }
          );
    } else {
      this.toasterService.showError('File not found');
    }
  }

  viewDashboard() {
    this.rotuer.navigate(['dashboard']);
  }

  onSubmit() {
    const request = {
      "id":this.generalInfo.id,
      "projectStatus": this.reviewForm.controls.status.value,     
      "projectStage":"",
      "solutionStatus":"",
      "approvedDeniedBy":this.authService.getUser().name,
      "clientComments": ""
    }

    if(this.reviewForm.controls.status.value == "denied") {
        request.clientComments = this.reviewForm.controls.revisions.value;
        request.projectStatus ="Solution Review"; 
        request.projectStage ="Site Evaluation"; 
        request.solutionStatus ="Denied"; 
    }else{
      request.projectStatus ="Solution Approved"; 
      request.projectStage ="Solution Selection"; 
      request.solutionStatus ="Approved"; 
    }

    this.submitSubscription = this.dashboardService.saveReviewApplication(request).subscribe(res => {
        sessionStorage.setItem("customerAddress", this.generalInfo.address)

        if(res.success) {
            this.rotuer.navigate(['thank-you',btoa(this.projectId),btoa(this.protectedProjectId), btoa(this.reviewForm.controls.status.value)]);
        }
    })
    
  }


  ngOnDestroy() {
      if(this.routeSubscription) {
          this.routeSubscription.unsubscribe();
      }

      if(this.reviewApplicationSubscription) {
          this.reviewApplicationSubscription.unsubscribe();
      }

      if(this.submitSubscription) {
          this.submitSubscription.unsubscribe();
      }
  }
}


