import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Component({
  selector: 'app-fail-thank-you',
  templateUrl: './fail-thank-you.component.html',
  styleUrls: ['./fail-thank-you.component.scss']
})
export class FailThankYouComponent implements OnInit {
  failThankYouPage = AppConfigService.settings.workFlow.confirmation.failThankYouPage;
  constructor() { }

  ngOnInit() {
  }

}
