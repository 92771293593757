import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-tenant',
  templateUrl: './success-tenant.component.html',
  styleUrls: ['./success-tenant.component.scss']
})
export class SuccessTenantComponent implements OnInit {
  successContent = AppConfigService.settings.workFlow.confirmation.tenant;
  constructor(
    private router: Router,
  ) { }

  navigateToComingSoon() {
    this.router.navigate(['/coming-soon']);
  }
  ngOnInit() {
  }

}
