import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';


@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
  styles: [`
        .ui-steps .ui-steps-item {
          width: 20%;
          margin-left: 20%;
        }

        .ui-steps.steps-custom {
            margin-bottom: 30px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
            padding: 0 1em;
            overflow: visible;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
            background-color: #0081c2;
            color: #FFFFFF;
            display: inline-block;
            width: 36px;
            border-radius: 50%;
            margin-top: -14px;
            margin-bottom: 10px;
        }

        .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
            color: #555555;
        }
    `],
  encapsulation: ViewEncapsulation.None
})
export class ThankYouComponent implements OnInit {

  items:any;
  activeIndex: number;
  displayText: string;
  projectId: string;
  projectData: any;
  projectAddress: string;
  projectStatus: string;
  reviewSolutionThankYouPageSection: any;

  
  private routeSubscription: Subscription;

  constructor( private router: Router, 
              private activatedRoute: ActivatedRoute,
              private dashboardService: DashboardService ) { }

  ngOnInit() {
    this.reviewSolutionThankYouPageSection = AppConfigService.settings.reviewSolutionThankYouPage;
    this.intializeCustomSteps();
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.projectId);
      this.projectStatus = atob(params.status);
    });
    this.projectAddress = sessionStorage.getItem("customerAddress");
    this.initializeData();
  }

  viewDashboard() {
    this.router.navigate(['dashboard']);
  }

  viewProject(){
    this.router.navigate(['review-solution', btoa(this.projectId)]);
  }

  initializeData(){
    if(this.projectStatus == 'approved'){
      this.displayText = 'The solution(s) for the project below will now be released to the customer for selection:';
      this.activeIndex = 1;
    }
    else if( this.projectStatus == 'denied' ){
      this.displayText = 'The solution(s) for the project below have been sent for revision:';
      this.activeIndex = 0;
    }
  }
  intializeCustomSteps() {
    this.items = [
      {
        label: 'Review Scopes',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        icon: 'fa fa-check',
        label: 'Solutions Approved',
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  viewGeneralInfo(){
    this.router.navigate(['dashboard']);
  }

  ngOnDestroy() {
    if(this.routeSubscription) {
        this.routeSubscription.unsubscribe();
    }
  }

}
