import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TenantService {
    getHostname(): string {
        if (environment.environmentName === 'DEV'
            && window.location.hostname === 'localhost') {
            // Bypass Host Name in case of localhost
            return 'tepev-dev.clearesult.com';
            //'pceev-dev.clearesult.com';
        }
        return window.location.hostname;
    }

    getTenantName(): string {
        let tenant = 'default';
        const host = window.location.hostname;
        if (environment.tenants.has(host)) {
            tenant = environment.tenants.get(host);
        }
        return tenant;
    }
    getQueryStringParms(): any {
        const loginConfig = 'theme=' +  this.getTenantName() +
        '&environment=' + environment.environmentName +
        '&authority=' + environment.adb2c.authority +
        '&resetPasswordPolicy=' + environment.adb2c.AdForgotUrl.replace(environment.adb2c.authority, '') +
        '&appId=' + environment.adb2c.clientID +
        '&redirectUrl=' + window.location.origin;
        return loginConfig;
    }
}
