import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-property-section',
  templateUrl: './property-section.component.html',
  styleUrls: ['./property-section.component.scss']
})
export class PropertySectionComponent implements OnInit, AfterContentChecked {
  @Input() input: any;
  @Input() group: FormGroup;
  showTable = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private workflowService: WorkflowService
  ) { }

  ngOnInit() {
    // console.log('input in ps', this.input);
    // console.log('group is ps', this.group);
  }

  ngAfterContentChecked() {
    // your code to update the model
    this.cdr.detectChanges();
  }

  onToggle() {
    this.showTable = false;
  }
  onChange(value) {
    if (value.toLowerCase().includes('tenant') || value.toLowerCase().includes('manager')) {
      this.workflowService.removeSessionStorageItem('propertyInformation');
    }
  }
}
