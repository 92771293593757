import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GaService implements OnDestroy {
  private gtag: any;
  private subscription: Subscription[] = [];
  private clientCode = ' ';

  constructor(private router: Router, private route: ActivatedRoute) {
    const host = window.location.hostname;
    if (host.indexOf('tep') >= 0) {
      this.clientCode = 'GTM-MMJRLFM';
    } else if (host.indexOf('pce') >= 0) {
      this.clientCode = 'GTM-MRHLGRMH';
    } else {
      this.clientCode = 'GTM-N4JJMT4N';
    }
  }

  private trackPackage(pageInfo: any) {
    this.gtag('config', this.clientCode, pageInfo);
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  initiateGMapTracking() {
    this.gtag = window['gtag'];

    if (this.gtag) {
      const utmSource = window.sessionStorage.getItem('utmSource');
      this.gtag('config', this.clientCode, { send_page_view: false });
      this.subscription.push(
        // this.name = this.route.snapshot.queryParamMap.get("paramName")
        this.route.queryParamMap.subscribe(queryParams => {
          if (!utmSource && queryParams.get('UTM_source') && queryParams.get('UTM_source') !== 'null'
            && queryParams.get('UTM_source') !== utmSource) {
            window.sessionStorage.setItem('utmSource', queryParams.get('UTM_source'));
            window.sessionStorage.setItem('utmMedium', queryParams.get('UTM_medium'));
          }
        })
      );
    }

    this.subscription.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const routeParams = event.url.split('/');
          const utmSource = window.sessionStorage.getItem('utmSource');
          const pagePath = event.urlAfterRedirects.indexOf('?') > -1 || !(utmSource && utmSource !== 'null') ? event.urlAfterRedirects :
            `${event.urlAfterRedirects}?UTM_source=${utmSource}&UTM_medium=${window.sessionStorage.getItem('utmMedium')}`;

          this.trackPackage({
            page_path: pagePath
          });

        }
      })
    );
  }
}
