import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { ContactInformationDialogService } from '../../services/contact-information-dialog/contact-information-dialog.service';
import { Logger } from 'src/app/core/logger.service';
import { Subscription } from 'rxjs';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { environment } from 'src/environments/environment';
import { BroadcastService } from '@azure/msal-angular';
import { MsalService} from '@azure/msal-angular';
import { ClientRegistrationService } from 'src/app/services/client-registration/client-registration.service';
import { ToasterService } from '../../services/toaster/toaster.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';

const log = new Logger('HeaderComponent');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() configuration: any;
  currentUser = null;
  header = AppConfigService.settings.header;
  navigation: any[] = []; 
  showServiceDetails = false;
  isLoggedIn = false;
  loginName: string;
  routerUrl:string;
  routertemp: Router;
  theeme: string;

  private subscription: Subscription;
  contactSubscription: Subscription;
  successCalled = false;
  toggle = null;
  menu = null;
  
  constructor(
    private router: Router,
    private appConfigService: AppConfigService,
    private contactInformationDialogService: ContactInformationDialogService,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private tenantService: TenantService,
    private clientRegistrationService: ClientRegistrationService,
    private toasterService: ToasterService,
    public loaderService: LoaderService,
    private appInsightsService: AppInsightsService
  ) {

    if (this.authService.getUser()) {
      this.isLoggedIn = true;
    }
  }

  logOut() {
    this.isLoggedIn = false;
    sessionStorage.removeItem("successCalled");
    sessionStorage.removeItem("role");
    this.authService.logout();
    this.router.navigate(['/home']);
  }

  openContactDialog(flag) {
    this.contactInformationDialogService.show(flag, 'header');
    this.toggleMenu();
  }

  onToggle() {
    this.showServiceDetails = false;
  }

  ngOnInit() {
    this.navigation = AppConfigService.settings.header.navigation;
    this.routertemp=this.router;
    this.subscription = this.broadcastService.subscribe("msal:loginFailure", (payload) => {
      console.log("msal:loginFailure");
    });

    this.subscription = this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
      this.successCalled = true;
      sessionStorage.setItem("successCalled",  JSON.stringify(this.successCalled));
      this.callGetCotactByEmail();
    });

    if (sessionStorage.getItem('msal.idtoken')) {
        this.loginName = this.authService.getUser().name;
        let alreadyLoggedIn = sessionStorage.getItem("successCalled");
          if (!JSON.parse(alreadyLoggedIn)) {
            this.callGetCotactByEmail();
          }
    }
  }

  onNavigate(nav: any) {
    if(this.isLoggedIn && nav.navItem != 'Program Details') {
        const role = sessionStorage.getItem('role');
        if(role == 'Utility Contact') {
            this.router.navigate(['/dashboard']);
        }
        else if(role === 'Property owner or manager' || role === 'Tenant or employee' || role === 'Contractor') {
            this.router.navigate(['/customer-dashboard']);
        }
    }
    else {
      let navigate = this.navigation.find(x => x.navItem == nav.navItem && x.active);
      this.router.navigate([navigate.navLink]);
    }
    this.toggleMenu();
  }

  callGetCotactByEmail() {
    this.isLoggedIn = true;
    const email = this.authService.getUser().idToken['emails'][0];
    this.contactSubscription = this.clientRegistrationService.GetContactByEmail(email).subscribe(res => {
      if(res) {
        sessionStorage.setItem('role', res.Role__c);
        this.checkUserRole(res.Role__c, email);
      }
    });
  }

  checkUserRole(role, email) {
   if (role === 'Utility Contact') {
    this.router.navigate(['/dashboard']);
  } else if (role === 'Property owner or manager' || role === 'Tenant or employee' || role === 'Contractor') {
    this.router.navigate(['/customer-dashboard']);
  } else {
    const message = 'User does not have access to Portal.';
    this.appInsightsService.logTrace(email + ' ' + message);
    this.toasterService.showError(message);
    setTimeout(() => {
      this.authService.logout();
    }, 3000);
  }
}

  login() {
    this.authService.authority = environment.adb2c.AdSignInUrl;
    this.loaderService.setSpinner(true);
    const extraQueryParameters = this.tenantService.getQueryStringParms();
    this.authService.loginRedirect(null, extraQueryParameters);
  }

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
    this.subscription.unsubscribe();
    }
  }

  toggleMenu() { 
    this.toggle = document.querySelector(".navbar-toggler");
    this.menu = document.querySelector(".nav");
    if (this.menu.classList.contains("active")) {
      this.menu.classList.remove("active");
      // adds the menu (hamburger) icon
      this.toggle.querySelector("span").classList.remove("n-close");
      this.toggle.querySelector("span").classList.add("n-bars");
    } else {
      this.menu.classList.add("active");
      // adds the close (x) icon
      this.toggle.querySelector("span").classList.remove("n-bars");
      this.toggle.querySelector("span").classList.add("n-close");
    }
  }
}
