import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { AssistYouComponent } from '../assist-you/assist-you.component';

@Component({
  selector: 'app-updated-contractor-bidding',
  templateUrl: './updated-contractor-bidding.component.html',
  styleUrls: ['./updated-contractor-bidding.component.scss'],
  styles: [`
  .ui-steps .ui-steps-item {
    width: 20%;
  }

  .ui-steps.steps-custom {
      margin-bottom: 30px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
      padding: 0 1em;
      overflow: visible;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
      background-color: #0081c2;
      color: #FFFFFF;
      display: inline-block;
      width: 36px;
      border-radius: 50%;
      margin-top: -14px;
      margin-bottom: 10px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
      color: #555555;
  }
`],
  encapsulation: ViewEncapsulation.None
})
export class UpdatedContractorBiddingComponent implements OnInit {
  constructor(private router : Router,
    public dialogService: DialogService,
    private activatedRoute: ActivatedRoute,) { }

textLanguage: any;
items:any;
activeIndex: number;
ref: DynamicDialogRef;
projectId: string;
protectedProjectId: string;
id: string;
routeSubscription: Subscription;

ngOnInit() {
  this.textLanguage = AppConfigService.settings.updatedContractorBidding;
  this.routeSubscription = this.activatedRoute.params.subscribe(params => {
  this.projectId = atob(params.projectId);
  this.protectedProjectId = atob(params.protectedProjectId);
  this.id = atob(params.id);
  });
  this.intializeCustomSteps();
  this.activeIndex = 2;
}

goBack(){
  this.router.navigate(['/next-steps',btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
}

intializeCustomSteps() {

this.items = [
{
label: 'Onsite Evaluation',
command: (event: any) => {
this.activeIndex = 0;
},
icon: 'fa fa-map-marker'
},
{
label: 'Scope Selection',
command: (event: any) => {
this.activeIndex = 0;
},
icon: 'fa fa-file-text-o'
},
{
label: 'Request Bids',
command: (event: any) => {
this.activeIndex = 0;
},
icon: 'fa fa-list-ul'
},
{
label: 'Contract Details',
command: (event: any) => {
this.activeIndex = 0;
},
icon: 'fa fa-shield'
},
{
icon: 'fa fa-check',
label: this.textLanguage.customStep5,
command: (event: any) => {
this.activeIndex = 1;
}
}
];
}

show(){
  this.ref = this.dialogService.open(AssistYouComponent, {
  data: {
  projectId : this.projectId,
  protectedProjectId : this.protectedProjectId
  },
  header: '',
  showHeader: false,
  dismissableMask: true,
  width: '50%'
  });
}

viewDashboard() {
  this.router.navigate(['customer-dashboard']);
}
nextStep(){
  this.router.navigate(['/contract-details',btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
}

}
