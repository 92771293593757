import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../services/toaster/toaster.service';
import { saveAs } from 'file-saver/FileSaver';

@Component({
  selector: 'app-uploaded-documents',
  templateUrl: './uploaded-documents.component.html',
  styleUrls: ['./uploaded-documents.component.scss']
})
export class UploadedDocumentsComponent implements OnInit {

  constructor(private dashboardService: DashboardService,
    private router: Router,
    private toasterService: ToasterService) { }
    
  cols: any;
  uploads: any;
  
  @Input() attachments : any;

  ngOnInit() {
    this.cols = [
      { field: 'attachmentName ', header: 'Document Name', width: '90%' },
      { field: 'action', header: 'Action', width: '10%'}
    ];
  }

  downloadDocument(attachment: any) {
    if (attachment !== undefined) {
      this.dashboardService.getDownloadDocument(attachment)
      .subscribe(
              success => {
                saveAs(success, attachment.attachmentName);
              },
              (error) => {
                  this.toasterService.showError('File not found');
              }
          );
    } else {
      this.toasterService.showError('File not found');
    }
  }


}
