import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('ClickOutsideDirective');
@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  show: boolean;

  constructor(private eref: ElementRef) { }
  @Input() clickOut: boolean;
  @Output() clickOutEvent: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    log.debug('directive called', this.clickOut);
    // if (this.clickOut && !event.path.includes(this.eref.nativeElement)) {
    //   this.clickOutEvent.emit();
    // }
    if (!this.eref.nativeElement.contains(event.target)) {
      this.clickOutEvent.emit();
    }

  }
}
