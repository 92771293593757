import { TenantService } from './../tenant/tenant.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('AppConfigService');
@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  static settings;

  constructor(private http: HttpClient,
              private tenantService: TenantService) { }

  load() {
    if(AppConfigService.settings == undefined) {
      const tenant = this.tenantService.getTenantName();
      log.info('Tenant', tenant);
      const clientFile = `assets/site-config/${tenant}.json`;
      var request = new XMLHttpRequest();
      request.open('GET', clientFile, false);  // request application settings synchronous
      request.send(null);
      const response = JSON.parse(request.responseText);
      AppConfigService.settings = response;
      }
  /*  return new Promise<void>((resolve, reject) => {
      this.http.get(clientFile).toPromise()
        .then((response) => {
          AppConfigService.settings = response;
          log.info('Config Loaded');
          log.info(AppConfigService.settings);
          resolve();
        }).catch((response: any) => {
          reject(`Could not load the config file`);
        });
    });*/
  }
}
