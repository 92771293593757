import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('CarouselWorkflowComponent');
@Component({
  selector: 'app-carousel-workflow',
  templateUrl: './carousel-workflow.component.html',
  styleUrls: ['./carousel-workflow.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CarouselWorkflowComponent implements OnInit {
  workflow = AppConfigService.settings.landingPage.carousel;
  page = 0;
  constructor() { }
  hello(event) {
    log.debug('event', event);
    this.page = event.page;
  }
  ngOnInit() {
  }

  scrollToTop(){
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
