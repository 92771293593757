import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Logger } from 'src/app/core/logger.service';
const log = new Logger('ConfirmationComponent');
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent implements OnInit {
  workFlowStatus: any;
  @Output() setBack = new EventEmitter<boolean>();
  constructor(private workflowService: WorkflowService) {
    log.debug('ConfirmationComponent----constructor');
    this.workflowService.setWorkFlowStatus({ step: 3 });
  }

  ngOnInit() {
    log.debug('ConfirmationComponent');
    // this.workflowService.getWorkFlowStatus().subscribe((workFlow) => {
    //   this.workFlowStatus = workFlow;
    // });
    this.workFlowStatus = this.workflowService.getUserType();
    this.workflowService.resetWorkFlowData();
  }

  goToBack() {
    this.setBack.emit(true);
  }

}
