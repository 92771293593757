import { Injectable } from '@angular/core';
//import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService {

    // instance: ApplicationInsights;

    constructor() {
        /* this.instance = new ApplicationInsights({ config: {
           instrumentationKey: environment.appInsights.instrumentationKey,
           enableCorsCorrelation: true,
           enableAutoRouteTracking: true
         } });
         this.instance.loadAppInsights();
         this.instance.trackPageView();*/
    }

    logPageView(name?: string, url?: string) {
        /* this.instance.trackPageView({
           name,
           uri: url
         });*/
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        // this.instance.trackEvent({ name}, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        // this.instance.trackMetric({ name, average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        //this.instance.trackException({ exception, severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        //this.instance.trackTrace({ message}, properties);
    }
}
