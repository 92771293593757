import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { Logger } from 'src/app/core/logger.service';

const log = new Logger('PropertyAndContactComponent');
@Component({
  selector: 'app-property-and-contact',
  templateUrl: './property-and-contact.component.html',
  styleUrls: ['./property-and-contact.component.scss']
})
export class PropertyAndContactComponent implements OnInit {
  @Output() setNext = new EventEmitter<boolean>();
  propertyInfo = AppConfigService.settings.workFlow.contactDetails;
  propertyAndContactform: FormGroup;
  submitted: boolean;
  displayText: any;
  constructor(
    private fb: FormBuilder,
    private workflowService: WorkflowService,
    private router: Router
  ) {
    log.debug('PropertyAndContactComponent----constructor');
  }

  ngOnInit() {
    this.displayText = AppConfigService.settings.workFlow;
    log.debug('PropertyAndContactComponent');
    let formGroup = {};
    formGroup = this.workflowService.createForm(this.propertyInfo);
    this.propertyAndContactform = new FormGroup(formGroup);
    log.debug('this.propertyAndContactform', this.propertyAndContactform.value);
    this.workflowService.setWorkFlowStatus({ step: 1 });
    const contactInformation = this.workflowService.getContactDetails();
    this.propertyAndContactform.patchValue({
      ...contactInformation
    });
    log.debug('this.propertyAndContactform.value', this.propertyAndContactform.value);
  }

  submitForm() {
    this.submitted = true;
    if (this.propertyAndContactform.valid) {
      this.workflowService.setContactDetails(this.propertyAndContactform.value);
      if (this.propertyAndContactform.get('userType') && this.propertyAndContactform.get('userType').value) {
        this.workflowService.setUserType(this.propertyAndContactform.get('userType').value);
        this.workflowService.setWorkFlowStatus({ ownerType: this.propertyAndContactform.get('userType').value });
        this.router.navigate(['workflow/property-information']);
      }
    } else {
      this.workflowService.validateAllFormFields(this.propertyAndContactform);
    }
  }
}
