import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { saveAs } from 'file-saver/FileSaver';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})
export class SolutionComponent implements OnInit {

  constructor(private dashboardService: DashboardService,
              private router: Router,
              private fb: FormBuilder,
              private toasterService: ToasterService) { }


  // solutionData: any[] = [];
  solutionForm = this.fb.group({
    solution: ['']
  });

  displayText: any;
  textLanguage: any;
  routeSubscription: Subscription;
  reviewApplicationSubscription: Subscription;
  showSelectScope = false;

  @Input() solutionData: any;
  @Output() solutionSelected  = new EventEmitter<String>();


  ngOnInit() {
    if (this.router.url.split('/')[1] === 'view-solution') {
      this.showSelectScope = true;
    }
    this.displayText = AppConfigService.settings.generalInformationPage.textLanguage;
    this.textLanguage = AppConfigService.settings.customerSolutionPage.textLanguage;

    if (this.solutionData != null) {
      this.solutionData.forEach(data => {
          data.totalChargers = data.chargers.reduce((toal, q) => {
            return  toal + q.quantity;
        }, 0);
          data.totalChargersCost = data.chargers.reduce((toalCost, d) => {
            if (d.quantity != null && d.quantity > 0) {
              return  toalCost + d.cost;
            } else {
              d.cost = 0;
              return  toalCost + d.cost;
            }
          }, 0);
      });
    }

  }

  downloadDocument(solution: any) {
    if (solution.attachment !== undefined) {
      this.dashboardService.getDownloadDocument(solution.attachment)
      .subscribe(
              success => {
                saveAs(success, solution.attachment.attachmentName);
              },
              (error) => {
                  this.toasterService.showError('File not found');
              }
          );
    } else {
      this.toasterService.showError('File not found');
    }
  }


  selecteSolution(event) {
    this.solutionSelected.emit(this.solutionForm.controls.solution.value);
  }

  ngOnDestroy() {
    if (this.reviewApplicationSubscription) {
      this.reviewApplicationSubscription.unsubscribe();
    }

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }


}
