import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private messageService: MessageService
  ) { }

  showSuccess(message) {
    this.messageService.add({ severity: 'success', summary: 'Success Message', detail: message });
  }

  showInfo() {
    this.messageService.add({ severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks' });
  }

  showWarn() {
    this.messageService.add({ severity: 'warn', summary: 'Warn Message', detail: 'There are unsaved changes' });
  }

  showError(errorMsg = 'Validation failed') {
    this.messageService.add({ severity: 'error', summary: 'Error Message', detail: errorMsg });
  }
}
