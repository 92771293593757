import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {

  records: Project[];
  searchRecords: Project[];
  cols: any;
  noRecordsFound: boolean = false;
  dashboardSection: any;
  projectForm = this.fb.group({
    search: [''],
    sortby: ['1'],
    sortas: ['0']
  });

  private dashboardSubscription: Subscription;
  private totalRecords: number;
  first: number;

  constructor(private fb: FormBuilder,
              private router: Router,
              private dashboardService: DashboardService) { }

  ngOnInit() {
    this.dashboardSection = AppConfigService.settings.dashboard;
    this.cols = [
        { field: 'projectStage', header: 'Stage', width: '25%' },
        { field: 'projectId', header: 'Project ID', width: '10%'},
        { field: 'businessName', header: 'Business Name', width: '25%'},
        { field: 'fullAddress', header: 'Project address', width: '30%'},
        { field: 'chargers', header: 'Chargers', width: '10%'},
        { field: 'button', header: '', width: '15%'}
    ];
    this.loadRecords();
  }

  loadRecords() {
    this.dashboardSubscription = this.dashboardService.getProjects().subscribe(response => {
      if (response) {
        this.records = response;
      }
    });
  }

  loadOtherApplications() {
    this.dashboardSubscription = this.dashboardService.getProjectBySearch('*').subscribe(response => {
      if (response) {
        this.searchRecords = response;
        this.totalRecords = this.searchRecords.length;
        this.first = 0;
        if (this.searchRecords.length >= 1) {
          this.noRecordsFound = false;
          this.sort();
        } else {
          this.noRecordsFound = true;
        }
      }
    });
  }

  onTabChange(index: number) {
    if (index == 0)  {
      this.loadRecords();
      this.projectForm.controls.search.setValue('');
      this.projectForm.controls.sortby.setValue('1');
      this.projectForm.controls.sortas.setValue('0');
    } else if (index == 1) {
      this.loadOtherApplications();
    }
  }

  viewProject(col: any) {
    this.router.navigate(['/review-solution', btoa(col.projectId), btoa(col.protectedProjectId)]);
  }

  search() {
    if (this.projectForm.value.search) {
      this.dashboardSubscription = this.dashboardService.getProjectBySearch(this.projectForm.value.search).subscribe(response => {
        if (response) {
          this.searchRecords = response;
          this.first = 0;
          if (this.searchRecords.length >= 1) {
            this.noRecordsFound = false;
            this.sort();
          } else {
            this.noRecordsFound = true;
          }
        }
      });
    } else {
      this.loadOtherApplications();
    }
  }

  sort() {
  if (this.searchRecords) {
    // sort by Project Stage
    if (this.projectForm.value.sortby == 0) {
      // ASC
      if (this.projectForm.value.sortas == 0) {
        this.searchRecords.sort(function (a, b) {
          return a.projectStage.localeCompare(b.projectStage);
        });
        // DESC
      } else if (this.projectForm.value.sortas == 1) {
        this.searchRecords.sort(function (a, b) {
          return b.projectStage.localeCompare(a.projectStage);
        });
      }
    }
    // sort by Project Id
    if (this.projectForm.value.sortby == 1) {
      // ASC
      if (this.projectForm.value.sortas == 0) {
        this.searchRecords.sort(function (a, b) {
          return a.projectId.localeCompare(b.projectId);
        });
        // DESC
      } else if (this.projectForm.value.sortas == 1) {
        this.searchRecords.sort(function (a, b) {
          return b.projectId.localeCompare(a.projectId);
        });
      }
    }
    // sort by Chargers
    if (this.projectForm.value.sortby == 2) {
      // ASC
      if (this.projectForm.value.sortas == 0) {
        this.searchRecords.sort(function (a, b) {
          return a.chargers - b.chargers;
        });
        // DESC
      } else if (this.projectForm.value.sortas == 1) {
        this.searchRecords.sort(function (a, b) {
          return b.chargers - a.chargers;
        });
      }
    }
  }
  }

  ngOnDestroy() {
    if (this.dashboardSubscription) {
        this.dashboardSubscription.unsubscribe();
    }
  }

}
