import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContractorBiddingService {

  constructor( private httpService: HttpService ) { }

  getContractors(searchText): Observable<any> {
    return this.httpService.get('/Contractor/'+searchText);
  }

  getContractorsByProject(protectedProjectId, searchText): Observable<any> {
    return this.httpService.get('/Contractor/GetContractorsByProject/' + protectedProjectId + "/" + searchText);
  }

  requestBids(id,payload): Observable<any> {
    return this.httpService.put('/Project/RequestBids/'+id, payload);
  } 

}
