import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { StaticContactInformationComponent } from 'src/app/shared/components/static-contact-information/static-contact-information.component';
import { EligiblePropertyService } from 'src/app/services/eligible-property/eligible-property.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/shared/services/loader/loader.service';
import { Logger } from 'src/app/core/logger.service';
import { ToasterService } from 'src/app/shared/services/toaster/toaster.service';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { CLIENT_CONFIG } from 'src/constants/client-configuration';
import { ContactInformationDialogService } from 'src/app/shared/services/contact-information-dialog/contact-information-dialog.service';
import { AppInsightsService } from 'src/app/services/app-insights.service';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
const log = new Logger('NotEligiblePropertyComponent');
@Component({
  selector: 'app-not-eligible-property',
  templateUrl: './not-eligible-property.component.html',
  styleUrls: ['./not-eligible-property.component.scss'],
})
export class NotEligiblePropertyComponent implements OnInit, OnDestroy {
  @Output() enableThankyou = new EventEmitter<boolean>();
  serviceForm: FormGroup;
  notEligibleSection = AppConfigService.settings.notEligiblePage.notEligibleSection;
  contactInfoSubscription: Subscription;
  constructor(
    public dialogService: DialogService,
    public fb: FormBuilder,
    public workflowService: WorkflowService,
    private eligiblePropertyService: EligiblePropertyService,
    private router: Router,
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private contactInformationDialogService: ContactInformationDialogService,
    private appInsightsService: AppInsightsService,
    private authService: MsalService,
    private contractorService: ContractorService
  ) { }

  show(flag) {
    const ref = this.dialogService.open(StaticContactInformationComponent, {
      data: {
        id: '51gF3',
        newtext: 'sdddddddddd',
        iconWithPropertyText: flag
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });

    ref.onClose.subscribe((status) => {
      log.debug('closed dialog', status);
      if (status) {
        this.enableThankyou.emit(status);
      }

      // if (car) {
      //   this.messageService.add({ severity: 'info', summary: 'Car Selected', detail: 'Vin:' + car.vin });
      // }
    });
  }
  onSubmit() {
    const formData = {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
    };

    if (this.serviceForm.valid) {
      const serviceNumber = {
        serviceNumber: this.serviceForm.get('serviceNumber').value
      };
      this.eligiblePropertyService.verifyServiceNumber(serviceNumber).subscribe((response) => {
        if (response && response.success) {
          this.workflowService.setServiceNumber(serviceNumber);
          if (response.dataValues && response.dataValues.length) {
            response.dataValues.forEach((element) => {
              if (element.name === 'Address1') {
                formData.streetAddress = element.value;
              } else if (element.name === 'City') {
                formData.city = element.value;
              } else if (element.name === 'State') {
                formData.state = element.value;
              } else if (element.name === 'ZipCode') {
                formData.postalCode = element.value;
              }
            });
          }
          this.workflowService.setEligibleFormData(formData);

          if(sessionStorage.getItem('msal.idtoken')) {
            const email = this.authService.getUser().idToken['emails'][0];
            this.contactInfoSubscription = this.contractorService.getContactInfo(email).subscribe(res => {
              res.userType=""; 
              this.workflowService.setContactDetails(res);
              this.router.navigate(['workflow/property-information']);
            });
          }
          else {
              this.router.navigate(['workflow/contact-details']);
          }
        } else {
          this.show(true);
        }
      }, (error) => {
        // this.show(true);
        //  this.toasterService.showError(error.message);
        this.handleErrors(error);
      });
      // if (parseInt(this.serviceForm.get('serviceNumber').value, 10) === 1111111111) {
      //   this.loaderService.setSpinner(true);
      //   setTimeout(() => {
      //     this.loaderService.setSpinner(false);
      //     this.router.navigate(['workflow/contact-details']);
      //   }, 1000);

      // } else {
      //   this.show(true);
      // }

    } else {
      this.workflowService.validateAllFormFields(this.serviceForm);
      return;
    }
  }

  handleErrors(error) {
    if (error && error.error) {
      if (error.error.errorCode === 'e001') {
        this.toasterService.showError(error.error.message);
      } else {
        this.appInsightsService.logException(error);
        this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
      }
    } else {
      this.appInsightsService.logException(error);
      this.toasterService.showError(CLIENT_CONFIG.errors.serverErrors);
    }
  }

  ngOnInit() {
    this.serviceForm = this.fb.group({
      serviceNumber: ['', Validators.required]
    });
  }

  openContactDialog(flag) {
    this.contactInformationDialogService.show(flag, 'header');
  }

  ngOnDestroy() {
    if(this.contactInfoSubscription) {
      this.contactInfoSubscription.unsubscribe();
    }
  }

}
