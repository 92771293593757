import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigService } from 'src/app/services/configuration/app-config.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AssistYouComponent } from '../assist-you/assist-you.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-incentive-reserved',
  templateUrl: './incentive-reserved.component.html',
  styleUrls: ['./incentive-reserved.component.scss'],
  styles: [`
  .ui-steps .ui-steps-item {
    width: 20%;
  }

  .ui-steps.steps-custom {
      margin-bottom: 30px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
      padding: 0 1em;
      overflow: visible;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-number {
      background-color: #0081c2;
      color: #FFFFFF;
      display: inline-block;
      width: 36px;
      border-radius: 50%;
      margin-top: -14px;
      margin-bottom: 10px;
  }

  .ui-steps.steps-custom .ui-steps-item .ui-steps-title {
      color: #555555;
  }
`],
  encapsulation: ViewEncapsulation.None
})
export class IncentiveReservedComponent implements OnInit {
  constructor(private router : Router,
              public dialogService: DialogService,
              private activatedRoute: ActivatedRoute,) { }
  
  textLanguage: any;
  items:any;
  activeIndex: number;
  ref: DynamicDialogRef;
  projectId: string;
  protectedProjectId: string;
  id: string;
  routeSubscription: Subscription;

  ngOnInit() {
    this.textLanguage = AppConfigService.settings.incentiveReservedDetails;
    this.routeSubscription = this.activatedRoute.params.subscribe(params => {
      this.projectId = atob(params.projectId);
      this.protectedProjectId = atob(params.protectedProjectId);
      this.id = atob(params.id);
    });
    this.intializeCustomSteps();
    this.activeIndex = 1;
  }

  goBack(){
    this.router.navigate(['/next-steps',btoa(this.id), btoa(this.projectId), btoa(this.protectedProjectId)]);
  }

  intializeCustomSteps() {

    this.items = [
      {
        label: 'Onsite Evaluation',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-map-marker'
      },
      {
        label: 'Scope Selection',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-file-text-o'
      },
      {
        label: 'Request Bids',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-list-ul'
      },
      {
        label: 'Contract Details',
        command: (event: any) => {
          this.activeIndex = 0;
        },
        icon: 'fa fa-shield'
      },
      {
        icon: 'fa fa-check',
        label: this.textLanguage.customStep5,
        command: (event: any) => {
          this.activeIndex = 1;
        }
      }
    ];
  }

  show() {
    this.ref = this.dialogService.open(AssistYouComponent, {
      data: {
        projectId : this.projectId,
        protectedProjectId : this.protectedProjectId,
        id: this.id
      },
      header: '',
      showHeader: false,
      dismissableMask: true,
      width: '50%'
    });
  }

  viewDashboard() {
    this.router.navigate(['customer-dashboard']);
  }

  redirectToContractorPage(){
    this.router.navigate(['contract-details',btoa(this.id),  btoa(this.projectId),  btoa(this.protectedProjectId)]);
  }
  
  redirectToBidding(){
    this.router.navigate(['contractor-bidding',btoa(this.id),  btoa(this.projectId), btoa(this.protectedProjectId)]);
  }
}
